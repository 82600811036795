var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LeaderAttribute, getAttributeType } from "../store/api/core";
import { emptyFilters, setFilters, getFilters, updateFilters, } from "../providers/FilterProvider";
export var FilterKey;
(function (FilterKey) {
    FilterKey[FilterKey["Function"] = 0] = "Function";
    FilterKey[FilterKey["SubFunction"] = 1] = "SubFunction";
    FilterKey[FilterKey["Level"] = 2] = "Level";
    FilterKey[FilterKey["Location"] = 3] = "Location";
    FilterKey[FilterKey["Manager"] = 4] = "Manager";
    FilterKey[FilterKey["Ethnicity"] = 5] = "Ethnicity";
    FilterKey[FilterKey["Gender"] = 6] = "Gender";
})(FilterKey || (FilterKey = {}));
export var useSummary = function (_a) {
    var useGetOverviewQuery = _a.useGetOverviewQuery, useGetScoresQuery = _a.useGetScoresQuery;
    return function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        var level = (_a = getFilters()) === null || _a === void 0 ? void 0 : _a.level;
        var role = (_b = getFilters()) === null || _b === void 0 ? void 0 : _b.role;
        var manager = (_c = getFilters()) === null || _c === void 0 ? void 0 : _c.manager;
        var overview = useGetOverviewQuery({});
        var primarySecondaryFunctionMap = ((_d = overview === null || overview === void 0 ? void 0 : overview.data) === null || _d === void 0 ? void 0 : _d.subFunctionsToFunctionsMap) || {};
        var query = {
            role: !role || role == "" ? "" : role,
            level: !level || level == "" ? "" : level,
        };
        var lookupCodeMap = ((_e = overview === null || overview === void 0 ? void 0 : overview.data) === null || _e === void 0 ? void 0 : _e.lookupCodeMap) || {};
        var summaryLevel = query.level == "all" ? "" : query.level;
        var primaryRoleValue = getAttributeType(role) == LeaderAttribute.PrimaryRole
            ? role
            : getAttributeType(role) == LeaderAttribute.SecondaryRole
                ? primarySecondaryFunctionMap[role]
                : "";
        var secondaryRoleValue = getAttributeType(role) == LeaderAttribute.SecondaryRole ? role : "";
        var functionOptionData = useGetScoresQuery({
            filters: __assign(__assign({}, getFilters()), { role: "" }),
        });
        var subFunctionOptionData = useGetScoresQuery({
            filters: __assign(__assign({}, getFilters()), { role: getAttributeType(role) == LeaderAttribute.PrimaryRole
                    ? role
                    : getAttributeType(role) == LeaderAttribute.SecondaryRole
                        ? primarySecondaryFunctionMap[role]
                        : "" }),
        });
        var levelOptionData = useGetScoresQuery({
            filters: __assign(__assign({}, getFilters()), { level: "" }),
        });
        var allLeadersData = useGetScoresQuery({
            filters: {},
        });
        var managerOptionData = useGetScoresQuery({
            filters: __assign(__assign({}, getFilters()), { manager: "" }),
        });
        var distinct = function (value, index, self) {
            return self.indexOf(value) === index;
        };
        var exists = function (element) {
            if (typeof element === "string") {
                return element !== undefined && element !== null && element !== "";
            }
            else {
                return (element.label !== undefined &&
                    element.label !== null &&
                    element.value !== "");
            }
        };
        var lookupCodeMapLabeler = function (lookupCode) {
            return lookupCodeMap[lookupCode];
        };
        var buildUniqueOptions = function (data, lookupCodeKey, sortKey, labeler) {
            var _a;
            var uniqueSortedData = (_a = data === null || data === void 0 ? void 0 : data.leaders) === null || _a === void 0 ? void 0 : _a.map(function (leader) { return leader[lookupCodeKey]; }).filter(distinct).map(function (lookupCode) { return ({
                value: lookupCode,
                label: labeler(lookupCode),
            }); }).filter(exists).sort(function (a, b) { return a[sortKey].localeCompare(b[sortKey]); });
            uniqueSortedData === null || uniqueSortedData === void 0 ? void 0 : uniqueSortedData.unshift({ value: "", label: "All" });
            return uniqueSortedData;
        };
        var addSelectedToOptions = function (options, selected, label) {
            if (options) {
                var currentOptions = options.map(function (option) { return option.value; });
                if (selected && !currentOptions.includes(selected)) {
                    options.push({ value: selected, label: label || selected });
                }
            }
            return options;
        };
        var managerEmailToNameMap = Object.fromEntries(((_g = (_f = allLeadersData === null || allLeadersData === void 0 ? void 0 : allLeadersData.data) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.leaders.map(function (_a) {
            var manager_name = _a.manager_name, manager_email = _a.manager_email;
            return [manager_email, manager_name];
        })) || []);
        var managerOptions = (_j = (_h = managerOptionData === null || managerOptionData === void 0 ? void 0 : managerOptionData.data) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.leaders.map(function (_a) {
            var manager_email = _a.manager_email;
            return manager_email;
        }).filter(distinct).map(function (manager_email) { return ({
            value: manager_email,
            label: managerEmailToNameMap[manager_email],
        }); }).filter(exists).sort(function (a, b) { return a.label.localeCompare(b.label); });
        (managerOptions || []).unshift({ value: "", label: "All" });
        var onFilterChange = function (filterKey, value) {
            var _a;
            var emptyStringConvertables = new Set([
                FilterKey.Function,
                FilterKey.SubFunction,
                FilterKey.Level,
            ]);
            if (emptyStringConvertables.has(filterKey) && value === "") {
                // Convert the empty string value to "all"
                value = "all";
            }
            if (filterKey === FilterKey.Function ||
                filterKey === FilterKey.SubFunction) {
                if (filterKey === FilterKey.SubFunction && value === "all") {
                    // If setting the sub-function to "all", then set the function
                    var selectedFunction = primarySecondaryFunctionMap[role];
                    updateFilters({ role: selectedFunction });
                }
                else {
                    updateFilters({ role: value });
                }
                return;
            }
            var filterOperations = (_a = {},
                _a[FilterKey.Level] = function () { return updateFilters({ level: value }); },
                _a[FilterKey.Location] = function () {
                    return updateFilters({ locations: Array.isArray(value) ? value : [] });
                },
                _a[FilterKey.Manager] = function () { return updateFilters({ manager: value }); },
                _a[FilterKey.Gender] = function () { return updateFilters({ gender: value }); },
                _a[FilterKey.Ethnicity] = function () { return updateFilters({ ethnicity: value }); },
                _a);
            (filterOperations[filterKey] ||
                (function () { return console.error("no filter key ".concat(filterKey)); }))();
        };
        var clearFilters = function () {
            setFilters(__assign({}, emptyFilters));
        };
        var roleOptions = {
            selected: primaryRoleValue,
            options: addSelectedToOptions(buildUniqueOptions((_k = functionOptionData === null || functionOptionData === void 0 ? void 0 : functionOptionData.data) === null || _k === void 0 ? void 0 : _k.data, "function", "label", lookupCodeMapLabeler), primaryRoleValue, lookupCodeMap[primaryRoleValue], lookupCodeMapLabeler),
        };
        var subRole = {
            selected: secondaryRoleValue,
            options: addSelectedToOptions(buildUniqueOptions((_l = subFunctionOptionData === null || subFunctionOptionData === void 0 ? void 0 : subFunctionOptionData.data) === null || _l === void 0 ? void 0 : _l.data, "sub_function", "label", lookupCodeMapLabeler), secondaryRoleValue, lookupCodeMap[secondaryRoleValue]),
        };
        var levelOptions = {
            selected: summaryLevel,
            options: addSelectedToOptions(buildUniqueOptions((_m = levelOptionData === null || levelOptionData === void 0 ? void 0 : levelOptionData.data) === null || _m === void 0 ? void 0 : _m.data, "level", "value", lookupCodeMapLabeler), level, lookupCodeMap[level]),
        };
        var managerOptionSet = {
            selected: manager || "",
            options: addSelectedToOptions(managerOptions, manager, managerEmailToNameMap[manager]),
        };
        return {
            role: roleOptions,
            subRole: subRole,
            level: levelOptions,
            manager: managerOptionSet,
            onFilterChange: onFilterChange,
            clearFilters: clearFilters,
        };
    };
};
