var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { styled, TableHead, useTheme, Box, Pagination, Typography, } from "@mui/material";
import MUITable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowSelector from "./ArrowSelector";
import ArrowSelected from "./ArrowSelected";
export var ValidSortDirections;
(function (ValidSortDirections) {
    ValidSortDirections["ASC"] = "asc";
    ValidSortDirections["DESC"] = "desc";
})(ValidSortDirections || (ValidSortDirections = {}));
var ShowingInfoContainer = styled(Typography)(function () { return ({
    fontFamily: "Avenir Book",
    fontSize: "15px",
    height: "41px",
    marginBottom: "8px",
}); });
var sorter = function (_a) {
    var columnIndex = _a.columnIndex, isDescending = _a.isDescending;
    return function (a, b) {
        var one = a[columnIndex] != null ? a[columnIndex] : "";
        var two = b[columnIndex] != null ? b[columnIndex] : "";
        one = Object.prototype.hasOwnProperty.call(one, "sort") ? one.sort : one;
        two = Object.prototype.hasOwnProperty.call(two, "sort") ? two.sort : two;
        if (one === two) {
            return 0;
        }
        var multiplier = isDescending ? -1 : 1;
        if (one == undefined) {
            return multiplier;
        }
        else if (two == undefined) {
            return -multiplier;
        }
        else if (one > two) {
            return -multiplier;
        }
        return multiplier;
    };
};
var getInitialDescendings = function (rawSortColumnIndexes, rawSortDirections) {
    if (rawSortColumnIndexes == null || rawSortColumnIndexes == null) {
        return [];
    }
    var sortColumnIndexes = Array.isArray(rawSortColumnIndexes)
        ? rawSortColumnIndexes
        : [rawSortColumnIndexes];
    var sortDirections = Array.isArray(rawSortDirections)
        ? rawSortDirections
        : [rawSortDirections];
    return sortColumnIndexes.map(function (column, index) { return ({
        columnIndex: column,
        isDescending: sortDirections[index] == ValidSortDirections.DESC,
    }); });
};
var Table = function (props) {
    var theme = useTheme();
    var rows = __spreadArray([], props.data, true);
    var _a = React.useState(getInitialDescendings(props.defaultSortColumnIndex, props.defaultSortDirection)), descendings = _a[0], setDescendings = _a[1];
    var _b = React.useState(1), currentPage = _b[0], setCurrentPage = _b[1];
    var formatters = props.headers.map(function (header, index) {
        return (props.formatters || [])[index]
            ? (props.formatters || [])[index]
            : function (echo) { return echo; };
    });
    var isColumnDescending = function (column) {
        var is = descendings.find(function (_a) {
            var columnIndex = _a.columnIndex, isDescending = _a.isDescending;
            return columnIndex == column && isDescending;
        });
        var isNot = descendings.find(function (_a) {
            var columnIndex = _a.columnIndex, isDescending = _a.isDescending;
            return columnIndex == column && isDescending == false;
        });
        return is ? true : isNot ? false : undefined;
    };
    var isLastColumnDescending = function (column) {
        if (descendings.length <= 0 ||
            column != descendings[descendings.length - 1].columnIndex) {
            return undefined;
        }
        return isColumnDescending(column);
    };
    var sortRows = function (_a) {
        var columnIndex = _a.columnIndex, isDescending = _a.isDescending;
        return function () {
            if ((props.unsortableColumns || []).includes(columnIndex)) {
                return;
            }
            setDescendings(function (directions) { return __spreadArray(__spreadArray([], directions.filter(function (command) { return columnIndex != command.columnIndex; }), true), [
                { columnIndex: columnIndex, isDescending: isDescending },
            ], false); });
        };
    };
    descendings.forEach(function (sortCommand) {
        rows.sort(sorter(sortCommand));
    });
    var rowsPerPage = props.rowsPerPage || 10;
    var numberOfPages = Math.ceil(rows.length / rowsPerPage);
    var paginationThreshold = props.paginationThreshold || 10;
    var paginationEnabled = paginationThreshold > 0 && paginationThreshold <= rows.length;
    function handleChangePage(event, newPage) {
        setCurrentPage(newPage);
    }
    var TableHeadStyle = styled(TableHead)(function () { return ({
        fontFamily: "Avenir",
        fontWeight: 500,
        fontSize: "14px",
        padding: "24px 16px 12px",
        gap: "4px",
        "th::after": {
            content: '""',
            backgroundColor: "#69717F",
            position: "absolute",
            left: "0",
            right: "0",
            bottom: "0",
            height: "1px",
        },
        "th:first-child::after": {
            content: '""',
            backgroundColor: "#69717F",
            position: "absolute",
            height: "1px",
        },
        "th:last-child::after": {
            content: '""',
            backgroundColor: "#69717F",
            position: "absolute",
            height: "1px",
        },
    }); });
    var TableBodyStyle = styled(TableBody)(function () { return ({
        background: "white",
        fontFamily: "Avenir Book",
        fontSize: "16px",
        alignItems: "center",
        padding: "0px",
        gap: "8px",
        "& .MuiTableRow-root.MuiTableRow-hover:hover td": {
            backgroundColor: "#EBFDFF !important",
        },
        "& .MuiTableRow-root.MuiTableRow-hover:hover td.highlighted-row": {
            backgroundColor: "#E5F6F8 !important",
        },
    }); });
    var HeaderContainer = styled(Box)(function () { return ({}); });
    var OuterArrowContainer = styled(Box)({
        display: "flex",
        flexDirection: "column",
        padding: "0 6px",
        height: "100%",
        width: "10px",
    });
    var ArrowContainer = styled(Box)({
        position: "absolute",
        bottom: "14px",
    });
    var UpArrowContainer = styled(Box)({
        position: "absolute",
        bottom: "13px",
    });
    var DownArrowContainer = styled(Box)({
        position: "absolute",
        bottom: "11px",
    });
    var Title = styled("div")(function () { return ({
        fontSize: "20px",
        borderWidth: "1px",
        borderStyle: "solid",
        padding: "0 10px 2px",
    }); });
    var StyledTableCell = styled(TableCell)(function () { return ({
        fontFamily: "Avenir Book",
        border: "none",
        color: "#4F5666",
        fontSize: "14px",
        padding: "12px 8px",
    }); });
    var StyledTableHeaderCell = styled(StyledTableCell)(function () { return ({
        fontFamily: "Avenir Medium",
        fontWeight: "500",
        color: "#69717F",
        border: "none",
        cursor: "pointer",
        padding: "8px 10px",
        borderBottom: "1px solid white",
        position: "relative",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
    }); });
    var StyledHeaderCellContainer = styled("div")(function () { return ({
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
    }); });
    var StyledTableRow = styled(TableRow)(function () { return ({
        "th:first-child": {
            paddingLeft: "8px",
            paddingRight: "8px",
        },
        "th:last-child": {
            paddingLeft: "8px",
            paddingRight: "8px",
        },
        "td:first-child": {
            paddingLeft: "8px",
            paddingRight: "8px",
        },
        "td:last-child": {
            paddingLeft: "8px",
            paddingRight: "8px",
        },
    }); });
    var TotalsTableRow = styled(TableRow)(function () { return ({
        backgroundColor: "rgba(240, 240, 240, 0.5)",
        td: {
            padding: "14px 10px",
        },
        "td:first-child": {
            paddingLeft: "8px",
            paddingRight: "8px",
        },
        "td:last-child": {
            paddingLeft: "8px",
            paddingRight: "8px",
        },
    }); });
    var PaginationContainer = styled(Box)(function () { return ({
        display: "flex",
        justifyContent: "center",
        marginTop: "43px",
        ".MuiPaginationItem-root": {
            fontFamily: theme.typography.fontFamily + " Medium",
            minWidth: "32px",
            height: "32px",
            padding: "3px 6px",
            margin: "0 3px",
            color: "#002136",
            borderRadius: "4px",
            backgroundColor: "#F6F7F8",
            border: "none",
        },
        ".MuiPaginationItem-root:hover:not(.MuiPaginationItem-ellipsis):not(.Mui-selected)": {
            background: "#EBFDFF",
        },
        ".MuiPaginationItem-ellipsis": {
            fontFamily: theme.typography.fontFamily + " Heavy",
        },
        ".MuiPaginationItem-page.Mui-selected": {
            color: "#146DA6",
            backgroundColor: "#B0F3FE",
            border: "1px solid #146DA6",
        },
        ".MuiPaginationItem-root.Mui-selected:hover": {
            background: "#B0F3FE",
        },
    }); });
    var showingInfoReturn = props.showingInfo &&
        props.showingInfo({
            total: props.data.length,
            count: numberOfPages != currentPage && props.data.length != 0
                ? rowsPerPage
                : props.data.length % rowsPerPage == 0 && props.data.length != 0
                    ? rowsPerPage
                    : props.data.length % rowsPerPage,
        });
    var showingInfoContainer = showingInfoReturn && typeof showingInfoReturn == "string" ? (_jsx(ShowingInfoContainer, { children: showingInfoReturn })) : (showingInfoReturn);
    return (_jsxs(_Fragment, { children: [showingInfoContainer, props.title && _jsx(Title, { children: props.title }), _jsx(TableContainer, __assign({ "data-testid": "TableComponent", sx: {
                    borderRadius: "2px",
                    elevation: "0",
                    boxShadow: "none",
                    overflowX: "visible",
                }, component: Paper }, { children: _jsxs(MUITable, __assign({ sx: {
                        minWidth: "100%",
                    } }, { children: [_jsx(TableHeadStyle, { children: _jsx(StyledTableRow, { children: props.headers.map(function (header, index) { return (_jsxs(StyledTableHeaderCell, __assign({ "data-testid": "header-".concat(index), onClick: sortRows({
                                        columnIndex: index,
                                        isDescending: isColumnDescending(index) == undefined
                                            ? true
                                            : !isColumnDescending(index),
                                    }), style: {
                                        verticalAlign: "bottom",
                                        mixBlendMode: "multiply",
                                        background: props.highlightColumnIndex === index
                                            ? theme.palette.grey.A200
                                            : "",
                                    } }, { children: [" ", _jsxs(StyledHeaderCellContainer, { children: [_jsx(HeaderContainer, { children: header }), !(props.unsortableColumns || []).includes(index) &&
                                                    (isLastColumnDescending(index) == undefined ? (_jsx(OuterArrowContainer, { children: _jsx(ArrowContainer, __assign({ "data-testid": "bothArrow-".concat(index) }, { children: _jsx(ArrowSelector, {}) })) })) : isLastColumnDescending(index) ? (_jsx(OuterArrowContainer, { children: _jsx(DownArrowContainer, __assign({ "data-testid": "downArrow-".concat(index) }, { children: _jsx(ArrowSelected, { isUp: false }) })) })) : (_jsx(OuterArrowContainer, { children: _jsx(UpArrowContainer, __assign({ "data-testid": "upArrow-".concat(index) }, { children: _jsx(ArrowSelected, { isUp: true }) })) })))] })] }), "header-".concat(index))); }) }) }), _jsxs(TableBodyStyle, { children: [props.totalsRow && (_jsx(TotalsTableRow, __assign({ "data-testid": "row-".concat(0) }, { children: props.totalsRow.map(function (column, columnIndex) { return (_jsx(StyledTableCell, __assign({ "data-testid": "cell-0-".concat(columnIndex), style: {
                                            mixBlendMode: "multiply",
                                            background: props.highlightColumnIndex === columnIndex
                                                ? theme.palette.grey.A200
                                                : "",
                                        } }, { children: column }), "cell-0-".concat(columnIndex))); }) }), 0)), rows
                                    .slice((currentPage - 1) * rowsPerPage, !paginationEnabled
                                    ? rows.length
                                    : (currentPage - 1) * rowsPerPage + rowsPerPage)
                                    .map(function (row, rowIndex) { return (_jsx(StyledTableRow, __assign({ "data-testid": "row-".concat(rowIndex + 1), hover: true, sx: __assign({}, (props.rowSx || {})) }, { children: row.map(function (cell, columnIndex) { return (_jsx(StyledTableCell, __assign({ "data-testid": "cell-".concat(rowIndex + 1, "-").concat(columnIndex), className: props.highlightColumnIndex === columnIndex
                                            ? "highlighted-row"
                                            : "", style: {
                                            background: props.highlightColumnIndex === columnIndex
                                                ? theme.palette.grey.A200
                                                : "",
                                            borderBottomLeftRadius: rows.length - 1 === rowIndex ? "12px" : "",
                                            borderBottomRightRadius: rows.length - 1 === rowIndex ? "12px" : "",
                                        } }, { children: _jsx(_Fragment, { children: formatters[columnIndex](props.replaceEmpty && (cell === "" || cell == null)
                                                ? props.replaceEmpty
                                                : cell) }) }), "cell-".concat(rowIndex + 1, "-").concat(columnIndex))); }) }), rowIndex + 1)); })] })] })) })), paginationEnabled && (_jsx(PaginationContainer, { children: _jsx(Pagination, { count: numberOfPages, variant: "outlined", shape: "rounded", page: currentPage, onChange: handleChangePage }) }))] }));
};
export var ColumnDescribedTable = function (_a) {
    var defaultSortKeyOrder = _a.defaultSortKeyOrder, columnDescriptions = _a.columnDescriptions, title = _a.title, replaceEmpty = _a.replaceEmpty, data = _a.data, rowSx = _a.rowSx, showingInfo = _a.showingInfo;
    var headers = [];
    var formatters = [];
    var defaultSortDirections = [];
    var totalsRow = [];
    var unsortableColumns = [];
    var defaultSortIndexes = {};
    var highlightColumnIndex;
    columnDescriptions.forEach(function (_a, index) {
        var header = _a.header, formatter = _a.formatter, defaultSortOrderKey = _a.defaultSortOrderKey, defaultSortDirection = _a.defaultSortDirection, totalCell = _a.totalCell, isUnsortable = _a.isUnsortable, isHighlighted = _a.isHighlighted;
        headers[index] = header || "";
        formatters[index] = formatter;
        if (defaultSortOrderKey) {
            defaultSortIndexes[defaultSortOrderKey] = index;
        }
        if (isHighlighted) {
            highlightColumnIndex = index;
        }
        defaultSortDirections[index] =
            defaultSortDirection || ValidSortDirections.ASC;
        if (totalCell) {
            totalsRow[index] = totalCell;
        }
        if (isUnsortable) {
            unsortableColumns.push(index);
        }
    });
    var defaultSortColumnIndex = defaultSortKeyOrder === null || defaultSortKeyOrder === void 0 ? void 0 : defaultSortKeyOrder.reduce(function (result, next) {
        return defaultSortIndexes[next.column] != undefined
            ? __spreadArray(__spreadArray([], result, true), [defaultSortIndexes[next.column]], false) : result;
    }, []);
    defaultSortKeyOrder === null || defaultSortKeyOrder === void 0 ? void 0 : defaultSortKeyOrder.forEach(function (_a) {
        var column = _a.column, direction = _a.direction;
        defaultSortDirections[defaultSortIndexes[column]] = direction;
    });
    var tableData = data.map(function (row) {
        return columnDescriptions.map(function (_a) {
            var valueTransformer = _a.valueTransformer;
            return valueTransformer(row);
        });
    });
    return (_jsx(Table, { headers: headers, formatters: formatters, defaultSortColumnIndex: defaultSortColumnIndex, defaultSortDirection: defaultSortDirections, highlightColumnIndex: highlightColumnIndex, totalsRow: totalsRow.length > 0 ? totalsRow : undefined, paginationThreshold: 10, unsortableColumns: unsortableColumns, title: title, replaceEmpty: replaceEmpty, data: tableData, rowSx: rowSx, showingInfo: showingInfo }));
};
export default Table;
