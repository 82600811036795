var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Box, styled } from "@mui/material";
import CloseIcon from "../../icons/CloseIcon";
var ResetButton = function (_a) {
    var label = _a.label, onClick = _a.onClick;
    var ResetButton = styled(Box)(function (_a) {
        var theme = _a.theme;
        return ({
            fontSize: "16px",
            fontFamily: theme.typography.fontFamily + " Medium",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "24px",
            color: "#4F5666",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
        });
    });
    var ResetTitle = styled(Box)({
        marginLeft: "4px",
    });
    return (_jsx(_Fragment, { children: _jsxs(ResetButton, __assign({ "data-testid": "resetButton", onClick: onClick }, { children: [_jsx(CloseIcon, {}), _jsx(ResetTitle, __assign({ component: "span" }, { children: label }))] })) }));
};
export default ResetButton;
