import { useGetSkillsCategoryQuery, useGetStatementLibrariesQuery, } from "../../store/api/core";
import { PillVariant } from "../Pill/Pill";
export var VeryImportantSkillTag = {
    text: "Very Important",
    variant: PillVariant.Warning,
};
export var CriticalSkillTag = {
    text: "Critical",
    variant: PillVariant.Error,
};
var importanceRatingMappings = {
    "Very Important": VeryImportantSkillTag,
    Critical: CriticalSkillTag,
};
export var useImpactDataSource = function (clientId, roleProfileId, skillGroup) {
    var _a, _b, _c, _d;
    var impactData = { description: "", skills: [] };
    var skillCategoryResponse = useGetSkillsCategoryQuery({
        clientId: clientId,
        roleProfileId: roleProfileId,
    });
    var skillCategoryResponseData = (_b = (_a = skillCategoryResponse === null || skillCategoryResponse === void 0 ? void 0 : skillCategoryResponse.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[0];
    var statementLibrariesResponse = useGetStatementLibrariesQuery({
        clientId: clientId,
        skillGroup: skillGroup,
    });
    var statementLibrariesResponseData = (_c = statementLibrariesResponse === null || statementLibrariesResponse === void 0 ? void 0 : statementLibrariesResponse.data) === null || _c === void 0 ? void 0 : _c.data;
    if (!skillCategoryResponseData || !statementLibrariesResponseData) {
        return impactData;
    }
    var statementLibraryMap = statementLibrariesResponseData.reduce(function (acc, library) {
        acc[library.statementLibraryUUID] = {
            name: (library === null || library === void 0 ? void 0 : library.name) || "",
            description: (library === null || library === void 0 ? void 0 : library.description) || "",
            impactModuleName: (library === null || library === void 0 ? void 0 : library.impactModuleName) || "",
        };
        return acc;
    }, {});
    var impactModules = {};
    var skillGroupData = (_d = skillCategoryResponseData.skillGroups.filter(function (group) { return group.name === skillGroup; })) === null || _d === void 0 ? void 0 : _d[0];
    skillGroupData === null || skillGroupData === void 0 ? void 0 : skillGroupData.skills.forEach(function (skill) {
        var lookups = statementLibraryMap[skill.statementLibraryUUID];
        var impactModule = (lookups === null || lookups === void 0 ? void 0 : lookups.impactModuleName) || "";
        if (!impactModules[impactModule]) {
            impactModules[impactModule] = [];
        }
        impactModules[impactModule].push({
            name: (lookups === null || lookups === void 0 ? void 0 : lookups.name) || "",
            description: (lookups === null || lookups === void 0 ? void 0 : lookups.description) || "",
            benchmark: skill.benchmark,
            tag: skill.importanceRating == "Very Important" ||
                skill.importanceRating == "Critical"
                ? importanceRatingMappings[skill.importanceRating]
                : undefined,
        });
    });
    Object.keys(impactModules)
        .sort(function (a, b) { return a.localeCompare(b); })
        .forEach(function (key) {
        impactData.skills.push({
            summary: key,
            skills: impactModules[key].sort(function (a, b) { var _a, _b; return (_a = a.name) === null || _a === void 0 ? void 0 : _a.localeCompare((_b = b.name) === null || _b === void 0 ? void 0 : _b.toString()); }),
        });
    });
    impactData["description"] = skillGroupData === null || skillGroupData === void 0 ? void 0 : skillGroupData.description;
    return impactData;
};
export var useCapabilitiesPotentialDataSource = function (clientId, roleProfileId, skillGroup) {
    var _a, _b, _c, _d, _e, _f;
    var resultingData = { description: "", skills: [] };
    // Temporary solution to get the capabilities data
    // 1. Get impact skill-category to find the levelCode
    // 2. Get capabilities skill-category using the levelCode
    // 3. Use the old statement libraries API to get the skill names and descriptions
    // Once success profiles are created, all four skill groups will be returned from the
    // same skill-categories API call and the lookups will come from client-models.
    var impactSkillCategoryResponse = useGetSkillsCategoryQuery({
        clientId: clientId,
        roleProfileId: roleProfileId,
    });
    var impactData = (_b = (_a = impactSkillCategoryResponse === null || impactSkillCategoryResponse === void 0 ? void 0 : impactSkillCategoryResponse.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[0];
    var capabilitiesSkillCategoryResponse = useGetSkillsCategoryQuery({
        clientId: clientId,
        levelCode: impactData === null || impactData === void 0 ? void 0 : impactData.roleProfile.levelCode,
    });
    var capabilitiesData = (_d = (_c = capabilitiesSkillCategoryResponse === null || capabilitiesSkillCategoryResponse === void 0 ? void 0 : capabilitiesSkillCategoryResponse.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d[0];
    var statementLibrariesResponse = useGetStatementLibrariesQuery({
        clientId: clientId,
        skillGroup: skillGroup,
    });
    var statementLibrariesResponseData = (_e = statementLibrariesResponse === null || statementLibrariesResponse === void 0 ? void 0 : statementLibrariesResponse.data) === null || _e === void 0 ? void 0 : _e.data;
    if (!impactData || !capabilitiesData || !statementLibrariesResponseData) {
        return resultingData;
    }
    var statementLibraryMap = statementLibrariesResponseData.reduce(function (acc, library) {
        acc[library.statementLibraryUUID] = {
            name: (library === null || library === void 0 ? void 0 : library.name) || "",
            description: (library === null || library === void 0 ? void 0 : library.description) || "",
        };
        return acc;
    }, {});
    var skillGroupData = (_f = capabilitiesData.skillGroups.filter(function (group) { return group.name === skillGroup; })) === null || _f === void 0 ? void 0 : _f[0];
    resultingData.skills = [
        {
            summary: "",
            skills: skillGroupData === null || skillGroupData === void 0 ? void 0 : skillGroupData.skills.map(function (skill) {
                var lookups = statementLibraryMap[skill.statementLibraryUUID];
                return {
                    name: (lookups === null || lookups === void 0 ? void 0 : lookups.name) || "",
                    description: (lookups === null || lookups === void 0 ? void 0 : lookups.description) || "",
                    benchmark: skill.benchmark,
                    tag: skill.importanceRating == "Very Important" ||
                        skill.importanceRating == "Critical"
                        ? importanceRatingMappings[skill.importanceRating]
                        : undefined,
                };
            }).sort(function (a, b) { var _a, _b; return (_a = a.name) === null || _a === void 0 ? void 0 : _a.localeCompare((_b = b.name) === null || _b === void 0 ? void 0 : _b.toString()); }),
        },
    ];
    resultingData.description = skillGroupData === null || skillGroupData === void 0 ? void 0 : skillGroupData.description;
    return resultingData;
};
