var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createRef, useEffect } from "react";
import ResetButton from "../ResetButton/ResetButton";
import { Box } from "@mui/material";
import { FilterResetEventName, MultiSelectFilter } from "./MultiSelectFilter";
import { useSummary } from "../../hooks/assessment";
import { emptyFilters, filterListeners, setFilters, } from "../../providers/FilterProvider";
import { usePermissions } from "../../hooks/permissions";
import { getAttributeType, getClientId, LeaderAttribute, useGetLeaderScoresQuery, useGetLeadershipOverviewQuery, useGetLookupsQuery, } from "../../store/api/core";
import { haveTalentReviewPages } from "../../common/featureFlags";
import { NineBoxFilter } from "../NineBoxFilter/NineBoxFilter";
import { FloatingSpinner } from "../SpinnerAndErrorer/SpinnerAndErrorer";
import { isObject, memoize } from "lodash";
export var AvailableFilters;
(function (AvailableFilters) {
    AvailableFilters[AvailableFilters["BusinessUnit"] = 0] = "BusinessUnit";
    AvailableFilters[AvailableFilters["NineBox"] = 1] = "NineBox";
    AvailableFilters[AvailableFilters["Function"] = 2] = "Function";
    AvailableFilters[AvailableFilters["SubFunction"] = 3] = "SubFunction";
    AvailableFilters[AvailableFilters["Level"] = 4] = "Level";
    AvailableFilters[AvailableFilters["Location"] = 5] = "Location";
    AvailableFilters[AvailableFilters["Manager"] = 6] = "Manager";
    AvailableFilters[AvailableFilters["Gender"] = 7] = "Gender";
    AvailableFilters[AvailableFilters["Ethnicity"] = 8] = "Ethnicity";
    AvailableFilters[AvailableFilters["Reset"] = 9] = "Reset";
    AvailableFilters[AvailableFilters["Spinner"] = 10] = "Spinner";
})(AvailableFilters || (AvailableFilters = {}));
/* eslint-disable  @typescript-eslint/no-explicit-any */
var getFunctionMap = function (data, current, result) {
    if (Array.isArray(data)) {
        return data.forEach(function (value) {
            getFunctionMap(value, current, result);
        });
    }
    if (isObject(data) &&
        (data === null || data === void 0 ? void 0 : data.code) &&
        getAttributeType(data === null || data === void 0 ? void 0 : data.code) == LeaderAttribute.PrimaryRole &&
        (data === null || data === void 0 ? void 0 : data.subFunctions)) {
        return getFunctionMap(Object.values(data.subFunctions), data.code, result);
    }
    if (isObject(data) &&
        (data === null || data === void 0 ? void 0 : data.code) &&
        getAttributeType(data === null || data === void 0 ? void 0 : data.code) == LeaderAttribute.SecondaryRole &&
        getAttributeType(current) == LeaderAttribute.PrimaryRole) {
        result.functionFilters[data.code] = current;
        if (!result.subFunctionFilters[current]) {
            result.subFunctionFilters[current] = new Set();
        }
        result.subFunctionFilters[current].add(data.code);
    }
    if (isObject(data)) {
        getFunctionMap(Object.values(data), current, result);
    }
};
/* eslint-enable  @typescript-eslint/no-explicit-any */
var getMemoizedFunctionMap = memoize(function (data) {
    var result = { functionFilters: {}, subFunctionFilters: {} };
    getFunctionMap(data, undefined, result);
    return result;
});
export var DefaultFilterSet = new Set([
    AvailableFilters.Location,
    AvailableFilters.BusinessUnit,
    AvailableFilters.Function,
    AvailableFilters.SubFunction,
    AvailableFilters.Level,
    AvailableFilters.Manager,
    AvailableFilters.Reset,
    AvailableFilters.Spinner,
]);
export var LandingPageFilters = function () {
    var nineBox = usePermissions().nineBox;
    var availableFilters = new Set([
        AvailableFilters.Location,
        AvailableFilters.BusinessUnit,
        AvailableFilters.Spinner,
    ]);
    if (haveTalentReviewPages() && nineBox) {
        availableFilters.add(AvailableFilters.NineBox);
    }
    var useSummaryInformation = useSummary({
        useGetScoresQuery: useGetLeaderScoresQuery,
        useGetOverviewQuery: useGetLeadershipOverviewQuery,
    });
    var filtersComponent = useFilterComponents({
        useSummary: useSummaryInformation,
        availableFilters: availableFilters,
    });
    return filtersComponent;
};
var FunctionFilter = function () {
    var _a;
    var response = useGetLookupsQuery({ clientId: getClientId() });
    var functionMap = getMemoizedFunctionMap((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data[0]);
    var filterRereader = createRef(null);
    var eventListener = function (_a, allNext) {
        var previous = _a.subFunctionFilter, settable = _a.functionFilter;
        var next = allNext.subFunctionFilter;
        var subChanges = (next || []).filter(function (value) { return !(previous === null || previous === void 0 ? void 0 : previous.includes(value)); });
        var changes = subChanges.map(function (value) { return functionMap.functionFilters[value]; });
        if (!changes.every(function (value) { return settable === null || settable === void 0 ? void 0 : settable.includes(value); })) {
            var mergedFunctions_1 = Array.from(new Set(changes.concat(settable)));
            if (!mergedFunctions_1.every(function (value) { return settable === null || settable === void 0 ? void 0 : settable.includes(value); }) ||
                mergedFunctions_1.length != (settable === null || settable === void 0 ? void 0 : settable.length)) {
                setTimeout(function () {
                    setFilters(__assign(__assign({}, allNext), { subFunctionFilter: next, sub_function: next, functionFilter: mergedFunctions_1, function: mergedFunctions_1 }));
                    setTimeout(function () {
                        filterRereader.current && filterRereader.current();
                    }, 0);
                }, 0);
            }
        }
    };
    useEffect(function () {
        filterListeners.add(eventListener);
        return function () {
            filterListeners.delete(eventListener);
        };
    }, [response]);
    return (_jsx(MultiSelectFilter, { label: "Function", entity: "function", filterRereader: filterRereader }));
};
var SubFunctionFilter = function () {
    var _a;
    var response = useGetLookupsQuery({ clientId: getClientId() });
    var functionMap = getMemoizedFunctionMap((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data[0]);
    var filterRereader = createRef(null);
    var eventListener = function (_a, allNext) {
        var previous = _a.functionFilter, settable = _a.subFunctionFilter;
        var next = allNext.functionFilter;
        var removedFunctions = (previous || []).filter(function (value) { return !(next === null || next === void 0 ? void 0 : next.includes(value)); });
        var removedSubFunctions = removedFunctions.reduce(function (current, value) {
            return current.concat(Array.from(functionMap.subFunctionFilters[value]));
        }, []);
        var newSettable = Array.from(new Set(__spreadArray([], (settable || []), true).filter(function (value) { return !removedSubFunctions.includes(value); })));
        if (!newSettable.every(function (value) { return (settable || []).includes(value); }) ||
            newSettable.length != (settable === null || settable === void 0 ? void 0 : settable.length)) {
            setTimeout(function () {
                setFilters(__assign(__assign({}, allNext), { functionFilter: next, function: next, subFunctionFilter: newSettable, sub_function: newSettable }));
                setTimeout(function () {
                    filterRereader.current && filterRereader.current();
                }, 0);
            }, 0);
        }
    };
    useEffect(function () {
        filterListeners.add(eventListener);
        return function () {
            filterListeners.delete(eventListener);
        };
    }, [response]);
    return (_jsx(MultiSelectFilter, { label: "Sub-Function", entity: "sub_function", filterRereader: filterRereader }));
};
export var useFilterComponents = function (_a) {
    var availableFilters = _a.availableFilters, clearFilters = _a.clearFilters;
    var showDiversitySpecific = usePermissions().showDiversitySpecific;
    var defaultClearFilters = function () {
        setFilters(emptyFilters);
    };
    var filterOrder = __spreadArray(__spreadArray([
        {
            key: AvailableFilters.Location,
            implementation: (_jsx(Box, { children: _jsx(MultiSelectFilter, { entity: "location", label: "Location 1" }) })),
        },
        {
            key: AvailableFilters.BusinessUnit,
            implementation: (_jsx(MultiSelectFilter, { entity: "business_unit", label: "Business Unit" })),
        },
        {
            key: AvailableFilters.Function,
            implementation: _jsx(FunctionFilter, {}),
        },
        {
            key: AvailableFilters.SubFunction,
            implementation: _jsx(SubFunctionFilter, {}),
        },
        {
            key: AvailableFilters.Level,
            implementation: _jsx(MultiSelectFilter, { label: "Level", entity: "level" }),
        },
        {
            key: AvailableFilters.Manager,
            implementation: _jsx(MultiSelectFilter, { label: "Manager", entity: "manager" }),
        },
        {
            key: AvailableFilters.NineBox,
            implementation: (_jsx(Box, { children: _jsx(NineBoxFilter, {}) })),
        }
    ], (showDiversitySpecific
        ? [
            {
                key: AvailableFilters.Gender,
                implementation: (_jsx(Box, { children: _jsx(MultiSelectFilter, { entity: "gender", label: "Gender" }) })),
            },
            {
                key: AvailableFilters.Ethnicity,
                implementation: (_jsx(Box, { children: _jsx(MultiSelectFilter, { entity: "ethnicity", label: "Ethnicity" }) })),
            },
        ]
        : []), true), [
        {
            key: AvailableFilters.Reset,
            implementation: (_jsx(ResetButton, { label: "Reset filters", onClick: function () {
                    (clearFilters || defaultClearFilters)();
                    setTimeout(function () {
                        window.dispatchEvent(new CustomEvent(FilterResetEventName, {}));
                    }, 0);
                } })),
        },
        {
            key: AvailableFilters.Spinner,
            implementation: _jsx(FloatingSpinner, {}),
        },
    ], false);
    return filterOrder
        .filter(function (_a) {
        var key = _a.key;
        return (availableFilters || DefaultFilterSet).has(key);
    })
        .map(function (_a) {
        var implementation = _a.implementation;
        return implementation;
    });
};
