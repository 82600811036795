var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { TabbedFilteredOverviewPage } from "../../components/FilteredOverviewPage/FilteredOverviewPage";
import { useSummary } from "../../hooks/assessment";
import { useGetClientPlatformQuery, useGetLeaderScoresQuery, useGetLeadershipOverviewQuery, useGetRoleProfileListQuery, getClientId, } from "../../store/api/core";
import { hasPermissionExist } from "../../store/api/permissions";
import { getRoute } from "../../routes/getAllRoutes";
import { Link } from "react-router-dom";
var SuccessProfilesPageImplementation = function () {
    var _a, _b, _c;
    var platformClients = useGetClientPlatformQuery({});
    var clientName = ((_c = (_b = (_a = platformClients === null || platformClients === void 0 ? void 0 : platformClients.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.name) || "Your";
    var useSummaryInformation = useSummary({
        useGetOverviewQuery: useGetLeadershipOverviewQuery,
        useGetScoresQuery: useGetLeaderScoresQuery,
    });
    var RoleProfilesTemp = function (_a) {
        var _b;
        var clientId = _a.clientId, route = _a.route;
        if (!clientId) {
            return _jsx("div", { children: "Loading..." });
        }
        var roleProfilesResponse = useGetRoleProfileListQuery({
            clientId: clientId,
            limit: 50,
            offset: 0,
            sort: ["name"],
        });
        return (_jsxs("div", { children: [_jsx("div", __assign({ style: { marginBottom: "30px" } }, { children: "TODO: Setup Filters. They are unconfigured." })), (_b = roleProfilesResponse.data) === null || _b === void 0 ? void 0 : _b.data.map(function (roleProfile) { return (_jsx("div", { children: _jsx(Link, __assign({ to: route
                            .replace(":roleProfileId", roleProfile.id)
                            .replace(":clientId", clientId) }, { children: roleProfile.name })) }, roleProfile.id)); })] }));
    };
    return (_jsx(TabbedFilteredOverviewPage, { useSummaryInformation: useSummaryInformation, title: "Success Profiles", secondaryHeadingTitle: "Success Profiles", tabs: [
            {
                label: "".concat(clientName, " Library"),
                hash: "client",
                content: (_jsx(RoleProfilesTemp, { clientId: getClientId(), route: getRoute("SuccessProfileRoleClient") })),
            },
            {
                label: "Heidrick Library",
                hash: "heidrick",
                content: (_jsx(RoleProfilesTemp, { clientId: "Master", route: getRoute("SuccessProfileRoleHeidrick") })),
            },
        ], backToButton: _jsx(_Fragment, {}) }));
};
export var SuccessProfilesPage = function () {
    return hasPermissionExist("ORG_DASHBOARD_UI/SUCCESS_PROFILES") ? (_jsx(SuccessProfilesPageImplementation, {})) : (_jsx("div", { children: "You don't have success profiles setup." }));
};
