var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Stack } from "@eightfold.ai/octuple";
import React from "react";
import { useParams } from "react-router-dom";
import { getDisplayableTimestamp } from "../../common/datetime";
import BackToButton from "../../components/BackToButton";
import { ControlledOverviewTabs } from "../../components/OveriewTabs/OverviewTabs";
import SecondaryMainHeading from "../../components/SecondMainHeading/SecondMainHeading";
import SpinnerAndErrorer from "../../components/SpinnerAndErrorer";
import { getRoute } from "../../routes/getAllRoutes";
import { useGetClientPlatformQuery, useGetSkillsCategoryQuery, useSuccessProfileDownloadReport, } from "../../store/api/core";
import { hasPermissionExist } from "../../store/api/permissions";
import { SuccessProfilesFunctionalImpact, SuccessProfilesOrganizationalImpact, SuccessProfilesCapabilities, SuccessProfilesPotential, } from "../../components/SuccessProfilesSkillGroup/SuccessProfilesSkillGroup";
import { useImpactDataSource, useCapabilitiesPotentialDataSource, CriticalSkillTag, } from "../../components/SuccessProfilesSkillGroup/SuccessProfileDataSource";
import styles from "./SuccessProfileRole.module.scss";
import { LookupField } from "../../components/LookupField/LookupField";
import Dropdown from "../../components/Dropdown";
import { ButtonVariant as IntelligenceButtonVariant } from "../../components/Button/Button";
var OverviewRoleSummary = function (_a) {
    var description = _a.description;
    return (_jsx("div", __assign({ className: styles.overviewRoleSummary }, { children: _jsxs(Stack, __assign({ direction: "vertical", gap: "s" }, { children: [_jsx("div", __assign({ className: styles.overviewRoleSummaryTitle }, { children: "Role Summary" })), _jsx("div", __assign({ className: styles.overviewRoleSummaryDescription }, { children: description }))] })) })));
};
var SummaryCardEntry = function (_a) {
    var title = _a.title, value = _a.value;
    return (_jsxs(Stack, __assign({ direction: "vertical", gap: "xxxs" }, { children: [_jsx("div", __assign({ className: styles.summaryTitle }, { children: title })), _jsx("div", __assign({ className: styles.summaryValue }, { children: value }))] })));
};
var SummaryCard = function (_a) {
    var children = _a.children;
    return _jsx("div", __assign({ className: styles.overviewSummaryCard }, { children: children }));
};
var BreakdownCard = function (_a) {
    var title = _a.title, breakdownNames = _a.breakdownNames;
    return (_jsx(SummaryCard, { children: _jsxs("div", __assign({ "data-testid": "breakdown-".concat(title) }, { children: [_jsx("div", __assign({ className: styles.breakdownTitle }, { children: title })), _jsx("div", { className: styles.breakdownDivider }), _jsx("div", { children: breakdownNames.length > 0 ? (breakdownNames.map(function (name, index) { return (_jsx("div", __assign({ className: styles.breakdownBodyItem }, { children: name }), index)); })) : (_jsx("div", __assign({ className: styles.breakdownBodyItem }, { children: "No critical factors" }))) })] })) }));
};
var getCriticalSkillNames = function (dataSource) {
    return dataSource.skills.reduce(function (acc, skill) {
        skill.skills.forEach(function (skill) {
            if (skill.tag && skill.tag === CriticalSkillTag) {
                acc.push(skill.name);
            }
        });
        return acc;
    }, []);
};
var CriticalForSuccess = function (props) {
    var functionalData = getCriticalSkillNames(useImpactDataSource(props.clientId, props.roleProfileId, "Functional Impact"));
    var organizationalData = getCriticalSkillNames(useImpactDataSource(props.clientId, props.roleProfileId, "Organizational Impact"));
    var capabilitiesData = getCriticalSkillNames(useCapabilitiesPotentialDataSource(props.clientId, props.roleProfileId, "Capabilities"));
    var potentialData = getCriticalSkillNames(useCapabilitiesPotentialDataSource(props.clientId, props.roleProfileId, "Potential"));
    if (!functionalData.length &&
        !organizationalData.length &&
        !capabilitiesData.length &&
        !potentialData.length) {
        // If there are no critical factors, then hide this section
        return _jsx("div", {});
    }
    return (_jsxs("div", __assign({ className: styles.breakdown, "data-testid": "critical-for-success" }, { children: [_jsx("div", __assign({ className: styles.breakdownCardTitle }, { children: "Critical for Success" })), _jsxs("div", __assign({ className: styles.breakdownCardContainer }, { children: [_jsxs("div", __assign({ className: styles.breakdownCardRow }, { children: [_jsx(BreakdownCard, { title: "Functional Impact", breakdownNames: functionalData }), _jsx(BreakdownCard, { title: "Organizational Impact", breakdownNames: organizationalData })] })), _jsxs("div", __assign({ className: styles.breakdownCardRow }, { children: [_jsx(BreakdownCard, { title: "Capabilities", breakdownNames: capabilitiesData }), _jsx(BreakdownCard, { title: "Potential", breakdownNames: potentialData })] }))] }))] })));
};
var SuccessProfileOverview = function (_a) {
    var _b, _c;
    var clientId = _a.clientId, roleProfileId = _a.roleProfileId;
    var response = useGetSkillsCategoryQuery({ clientId: clientId, roleProfileId: roleProfileId });
    var data = (_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c[0];
    if (!data) {
        return _jsx(SpinnerAndErrorer, { conditions: [response] });
    }
    var _d = data.roleProfile, description = _d.description, name = _d.name, functionCode = _d.functionCode, subFunctionCode = _d.subFunctionCode, levelCode = _d.levelCode;
    return (_jsxs("div", __assign({ className: styles.overviewTab }, { children: [_jsxs(SummaryCard, { children: [_jsxs(Stack, __assign({ direction: "vertical", gap: "m" }, { children: [_jsx(SummaryCardEntry, { title: "Role Name", value: name }), _jsx(SummaryCardEntry, { title: "Function", value: _jsx(LookupField, { clientId: clientId, code: functionCode }) }), _jsx(SummaryCardEntry, { title: "Sub-Function", value: _jsx(LookupField, { clientId: clientId, code: subFunctionCode }) }), _jsx(SummaryCardEntry, { title: "Level", value: _jsx(LookupField, { clientId: clientId, code: levelCode }) })] })), " "] }), _jsxs("div", __assign({ className: styles.overviewTabBody }, { children: [_jsx(OverviewRoleSummary, { description: description }), clientId && (_jsx(CriticalForSuccess, { clientId: clientId || "", roleProfileId: roleProfileId }))] }))] })));
};
var BannerTitle = function (_a) {
    var _b, _c;
    var roleName = _a.roleName, clientId = _a.clientId, lastUpdatedTs = _a.lastUpdatedTs;
    var name = "Heidrick Library";
    if (clientId) {
        var response = useGetClientPlatformQuery({ clientId: clientId });
        name = ((_c = (_b = response.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c[0].name) || "";
    }
    return (_jsxs("div", { children: [_jsx("div", __assign({ className: styles.secondaryTitle }, { children: roleName })), _jsxs(Stack, __assign({ direction: "horizontal", gap: "xxs" }, { children: [_jsx("div", __assign({ className: styles.secondaryTitleClientName }, { children: "".concat(name ? "".concat(name, " ") : "", "Success Profile") })), _jsx("div", __assign({ className: styles.secondaryTitleTimestamp }, { children: "Last Updated: ".concat(getDisplayableTimestamp(lastUpdatedTs)) }))] }))] }));
};
export var SuccessProfileRoleImplementation = function (_a) {
    var _b, _c;
    var clientId = _a.clientId, roleProfileId = _a.roleProfileId;
    var response = useGetSkillsCategoryQuery({ clientId: clientId, roleProfileId: roleProfileId });
    React.useEffect(function () {
        document.title = "Success Profiles";
    }, []);
    var data = (_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c[0];
    if (!data) {
        return _jsx(SpinnerAndErrorer, { conditions: [response] });
    }
    var _d = data.roleProfile, name = _d.name, lastUpdatedTs = _d.lastUpdatedTs;
    var DownloadMenu = function () {
        var params = useParams();
        var downloadQuery = useSuccessProfileDownloadReport({});
        var getDownloadLink = function (linkType) {
            downloadQuery.mutate({
                reportType: linkType == "CSV" ? "SPR_CSV" : "SPR",
                clientId: params.clientId ? params.clientId : "Master",
                roleProfileId: params.roleProfileId,
            }, {
                onSuccess: function (data) {
                    if (data.status == "200") {
                        var linkTag = document.createElement("a");
                        linkTag.href = data.download_link;
                        linkTag.target = "_blank";
                        linkTag.download = "success_profile_report";
                        linkTag.click();
                    }
                },
            });
        };
        var options = [
            {
                label: "Download PDF",
                onChange: function () {
                    getDownloadLink("PDF");
                },
            },
            {
                label: "Download CSV",
                onChange: function () {
                    getDownloadLink("CSV");
                },
            },
        ];
        return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: styles.downloadMenuWrapper }, { children: _jsx(Dropdown, { options: options, label: "Download", buttonOverrideProps: {
                        variant: IntelligenceButtonVariant.Secondary,
                        loading: downloadQuery.isPending,
                    } }) })) }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryMainHeading, { secondaryHeadingTitle: _jsx(BannerTitle, { roleName: name, clientId: clientId, lastUpdatedTs: lastUpdatedTs }), backToButton: _jsx(BackToButton, { title: "Success Profiles", url: "".concat(getRoute("SuccessProfiles")).concat(!clientId ? "#heidrick" : "") }) }), _jsxs("div", __assign({ style: { display: "flex", justifyContent: "space-between" } }, { children: [_jsx(ControlledOverviewTabs, { tabs: [
                            {
                                label: "Overview",
                                hash: "overview",
                                content: (_jsx(SuccessProfileOverview, { clientId: clientId, roleProfileId: roleProfileId })),
                            },
                            {
                                label: "Functional Impact",
                                hash: "functional",
                                content: (_jsx(SuccessProfilesFunctionalImpact, { clientId: clientId || "", roleProfileId: roleProfileId })),
                            },
                            {
                                label: "Organizational Impact",
                                hash: "organizational",
                                content: (_jsx(SuccessProfilesOrganizationalImpact, { clientId: clientId || "", roleProfileId: roleProfileId })),
                            },
                            {
                                label: "Capabilities",
                                hash: "capabilities",
                                content: (_jsx(SuccessProfilesCapabilities, { clientId: clientId || "", roleProfileId: roleProfileId })),
                            },
                            {
                                label: "Potential",
                                hash: "potential",
                                content: (_jsx(SuccessProfilesPotential, { clientId: clientId || "", roleProfileId: roleProfileId })),
                            },
                        ] }), _jsx(DownloadMenu, {})] }))] }));
};
export var SuccessProfileRole = function () {
    var params = useParams();
    return hasPermissionExist("ORG_DASHBOARD_UI/SUCCESS_PROFILES") ? (_jsx(SuccessProfileRoleImplementation, __assign({}, params))) : (_jsx("div", { children: "You don't have success profiles setup." }));
};
