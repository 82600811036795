var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled } from "@mui/material";
import React from "react";
var OuterContainer = styled(Box)({
    display: "flex",
    padding: "36px 32px",
    flexDirection: "column",
    alignItems: "flex-start",
    alignSelf: "stretch",
});
var TopCardContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
});
var BottomCardContainer = styled(Box)({
    marginTop: "30px",
    display: "table",
    height: "100%",
});
var CardBox = function (props) {
    var topRow = props.topRow;
    var bottomRow = props.bottomRow;
    return (_jsx(_Fragment, { children: _jsxs(OuterContainer, __assign({ "data-testid": "CardBox" }, { children: [topRow && (_jsx(TopCardContainer, { children: Array.isArray(topRow) &&
                        (topRow === null || topRow === void 0 ? void 0 : topRow.map(function (card) { return (_jsx(Box, __assign({ sx: {
                                marginBottom: "10px",
                                marginRight: "10px",
                            } }, { children: card }))); })) })), Array.isArray(bottomRow) && (_jsx(BottomCardContainer, { children: bottomRow === null || bottomRow === void 0 ? void 0 : bottomRow.map(function (card, index) { return (_jsxs(_Fragment, { children: [card, bottomRow.length - 1 !== index ? (_jsx(Box, { sx: {
                                    width: "12px",
                                } })) : null] })); }) }))] })) }));
};
export default CardBox;
