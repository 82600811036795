var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Pill from "../../Pill/Pill";
import styles from "./Skill.module.scss";
import Tooltip from "../../Tooltip";
import { TooltipPlacement } from "../../Tooltip/Tooltip";
var ProgressStepper = function (props) {
    var filled = Math.floor(props.currentStep);
    return (_jsxs("div", __assign({ className: styles.skillStepperContainer }, { children: [_jsx("div", __assign({ className: styles.skillStepperWrapper }, { children: _jsxs("div", __assign({ className: styles.progressBarSteps }, { children: [_jsx("div", { className: styles.progressBar, style: { width: "".concat(20 * props.currentStep, "%") } }), _jsx(Tooltip, __assign({ content: props.currentStep.toFixed(2), placement: TooltipPlacement.Bottom }, { children: _jsx("div", { className: styles.progressBarPointer }) })), _jsx("div", { className: styles.progressBarGap, style: { left: "25%" } }), _jsx("div", { className: styles.progressBarGap, style: { left: "50%" } }), _jsx("div", { className: styles.progressBarGap, style: { left: "75%" } })] })) })), _jsx("div", __assign({ className: styles.progressBarLabelContainer, style: {} }, { children: props.steps.map(function (label, index) { return (_jsx("div", __assign({ style: {
                        fontWeight: filled - 1 === index ? "bold" : "normal",
                        fontFamily: filled - 1 === index ? "Avenir Heavy" : "Avenir Book",
                        marginRight: index === props.steps.length - 1 ? "20px" : "0",
                    } }, { children: label }), label)); }) }))] })));
};
var SkillName = function (props) {
    return _jsx("div", __assign({ className: styles.skillName }, { children: props.name }));
};
var SkillDescription = function (props) {
    return _jsx("div", __assign({ className: styles.skillDescription }, { children: props.description }));
};
var Skill = function (props) {
    var benchmarkSteps = [
        "Undeveloped",
        "Emerging",
        "Proficient",
        "Advanced",
        "Expert",
    ];
    var highlightLabel = Math.round(props.benchmark) - 1;
    return (_jsxs("div", __assign({ className: styles.skillContainer }, { children: [_jsxs("div", __assign({ className: styles.skillLeftContainer }, { children: [_jsxs("div", __assign({ className: styles.skillNameContainer }, { children: [_jsx(SkillName, { name: "".concat(props.name).concat(highlightLabel > -1
                                    ? " (".concat(props.benchmark.toFixed(2), " - ").concat(benchmarkSteps[highlightLabel], ")")
                                    : "") }), props.tag && (_jsx(Pill, { text: props.tag.text, variant: props.tag.variant }))] })), _jsx(SkillDescription, { description: props.description })] })), _jsx(ProgressStepper, { steps: benchmarkSteps, currentStep: props.benchmark })] })));
};
export default Skill;
