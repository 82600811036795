var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { BoxLeaderScoreType, } from "./types/core";
import { LEVEL, FUNCTION, SUB_FUNCTION } from "../../Constants";
import { reverse, sortBy } from "lodash";
import { keepPreviousData, useMutation, useQuery, } from "@tanstack/react-query";
import { queryClient } from "../../ProviderShell";
import { isLiveEnvironment, isStorybookEnvironment, } from "../../common/testHelpers";
import { FilterContext, } from "../../providers/FilterProvider";
import { useContext, useState } from "react";
import { decrementSpinReason, incrementSpinReason, } from "../../providers/SpinnerProvider";
var apiBase = {
    dashboard: process.env.REACT_APP_API_KEY,
    ninebox: process.env.REACT_APP_API_NINE_BOX,
    platform: process.env.REACT_APP_API_KEY
        ? new URL(process.env.REACT_APP_API_KEY).origin
        : "",
};
export var normalizeScoreType = function (value) {
    var expectedTypes = [
        "Match",
        "Heidrick",
        "AI",
        "Functional",
        "Organizational",
        "Capabilities",
        "Potential",
    ];
    if (expectedTypes.includes(value)) {
        return value;
    }
    var mappings = {
        capability: "Capabilities",
        potential: "Potential",
        functional: "Functional",
        organizational: "Organizational",
        ai_score: "AI",
        heidrick_score: "Heidrick",
        overall_score: "Match",
    };
    return mappings[value] || value;
};
export var strengthScoreFunctor = function (assessmentIn) { return function (data) {
    var _a;
    var assessment = (assessmentIn === null || assessmentIn === void 0 ? void 0 : assessmentIn.toLowerCase()) == "capabilities"
        ? "capability"
        : assessmentIn === null || assessmentIn === void 0 ? void 0 : assessmentIn.toLowerCase();
    return assessment ? (_a = data === null || data === void 0 ? void 0 : data[assessment]) === null || _a === void 0 ? void 0 : _a.state : data === null || data === void 0 ? void 0 : data.state;
}; };
var transformScores = function (_a) {
    var fillEmpty = _a.fillEmpty;
    return function (data) {
        var _a;
        var getRawData = function (rawDataBreakdowns, functionIndexes, indexForLevelId) {
            var rawData = [];
            for (var _i = 0, _a = (data === null || data === void 0 ? void 0 : data.functions) || []; _i < _a.length; _i++) {
                var functionData = _a[_i];
                if (!functionIndexes[functionData.function_id]) {
                    continue;
                }
                var _b = functionIndexes[functionData.function_id], functionIndex = _b.functionIndex, functionName = _b.functionName, subFunctions = _b.subFunctions;
                if (!rawData[functionIndex]) {
                    rawData[functionIndex] = [];
                }
                rawData[functionIndex][0] = functionData.sub_functions[0];
                for (var _c = 0, _d = functionData.sub_functions[0].levels; _c < _d.length; _c++) {
                    var level = _d[_c];
                    rawData[functionIndex][indexForLevelId[level.level_id]] = level;
                }
                for (var _e = 0, _f = functionData.sub_functions.slice(1); _e < _f.length; _e++) {
                    var subFunction = _f[_e];
                    var subIndex = subFunctions[subFunction.sub_function_id] - 1;
                    if (!rawDataBreakdowns[functionName].data[subIndex]) {
                        rawDataBreakdowns[functionName].data[subIndex] = [];
                    }
                    rawDataBreakdowns[functionName].data[subIndex][0] = subFunction;
                    for (var _g = 0, _h = subFunction.levels; _g < _h.length; _g++) {
                        var level = _h[_g];
                        rawDataBreakdowns[functionName].data[subIndex][indexForLevelId[level.level_id]] = level;
                    }
                }
            }
            return rawData;
        };
        var getDataBreakdown = function (width, rawDataBreakdowns) {
            return (Object.entries(rawDataBreakdowns || {}).reduce(function (result, _a) {
                var yHeader = _a[0], _b = _a[1], headers = _b.headers, data = _b.data;
                result[yHeader] = {
                    headers: headers,
                    data: fillEmpties(__spreadArray([], data, true), width, headers.length, fillEmpty),
                };
                return result;
            }, {}) || {});
        };
        var stateForPrimaryRole = (_a = data === null || data === void 0 ? void 0 : data.functions) === null || _a === void 0 ? void 0 : _a.reduce(function (current, value) {
            var _a;
            return Object.assign(current, (_a = {},
                _a[value.function_id] = value.sub_functions[0].state,
                _a));
        }, {});
        var getCountForLevel = function (level, role) {
            if (!level || level.toLocaleLowerCase() == "all") {
                return role.leaders;
            }
            return role.levels.reduce(function (count, next) {
                if (next.level_id != level) {
                    return count;
                }
                return count + next.leaders;
            }, 0);
        };
        var getLeaderCount = function (level, role) {
            if (!role || role.toLocaleLowerCase() == "all") {
                return getCountForLevel(level, data.functions[0].sub_functions[0]);
            }
            else if ((role === null || role === void 0 ? void 0 : role[0]) == "F") {
                return data.functions.reduce(function (count, next) {
                    if (next.function_id != role) {
                        return count;
                    }
                    return (count += getCountForLevel(level, next.sub_functions[0]));
                }, 0);
            }
            else if ((role === null || role === void 0 ? void 0 : role[0]) == "S") {
                return data.functions.reduce(function (count, next) {
                    return (count += next.sub_functions.reduce(function (innerCount, innerNext) {
                        if (innerNext.sub_function_id != role) {
                            return innerCount;
                        }
                        return (innerCount += getCountForLevel(level, innerNext));
                    }, 0));
                }, 0);
            }
            return 0;
        };
        var getAssessmentStatus = function (data, functor) { return functor(data); };
        var getLevelAssessmentStatus = function (data, levelIn, functor) {
            if (!data) {
                return;
            }
            var level = (levelIn === null || levelIn === void 0 ? void 0 : levelIn.toUpperCase()) || "ALL";
            if (level == "ALL") {
                return getAssessmentStatus(data, functor);
            }
            for (var _i = 0, _a = data.levels; _i < _a.length; _i++) {
                var onLevel = _a[_i];
                if (onLevel.level_id == level) {
                    return getAssessmentStatus(onLevel, functor);
                }
            }
        };
        var subFunctionsForFunctionsMap = data.functions
            .slice(1)
            .reduce(function (result, _a) {
            var _b;
            var function_id = _a.function_id, sub_functions = _a.sub_functions;
            return Object.assign(result, (_b = {}, _b[function_id] = sub_functions.slice(1), _b));
        }, {});
        var subFunctionMap = Object.values(subFunctionsForFunctionsMap)
            .flat()
            .reduce(function (result, subRole) {
            var _a;
            return Object.assign(result, (_a = {}, _a[subRole.sub_function_id] = subRole, _a));
        }, {});
        var functionMap = data.functions.reduce(function (result, role) {
            var _a;
            if (role.function_id.toUpperCase() == "ALL") {
                return result;
            }
            return Object.assign(result, (_a = {},
                _a[role.function_id] = role.sub_functions[0],
                _a));
        }, {});
        var getScore = function (roleIn, levelIn, functor) {
            var level = (levelIn === null || levelIn === void 0 ? void 0 : levelIn.toUpperCase()) || "ALL";
            var role = (roleIn === null || roleIn === void 0 ? void 0 : roleIn.toUpperCase()) || "ALL";
            //const assessment =
            //  assessmentIn?.toLowerCase() == "capabilities"
            //    ? "capability"
            //    : assessmentIn?.toLowerCase();
            if (getAttributeType(role) == LeaderAttribute.All) {
                return getLevelAssessmentStatus(data.functions[0].sub_functions[0], level, functor);
            }
            var roles = getAttributeType(role) == LeaderAttribute.PrimaryRole
                ? functionMap
                : subFunctionMap;
            return getLevelAssessmentStatus(roles[role], level, functor);
        };
        return {
            subFunctionsForFunctionsMap: subFunctionsForFunctionsMap,
            stateForPrimaryRole: stateForPrimaryRole,
            functionMap: functionMap,
            getDataBreakdown: getDataBreakdown,
            getRawData: getRawData,
            data: data,
            getScore: getScore,
            getLeaderCount: getLeaderCount,
        };
    };
};
var fillEmpties = function (data, xLength, yLength, fillEmpty) {
    return Array.from(Array(yLength).keys()).map(function (yIndex) {
        return Array.from(Array(xLength).keys()).map(function (xIndex) { return (data[yIndex] || [])[xIndex] || fillEmpty(); });
    });
};
export var LeaderAttribute;
(function (LeaderAttribute) {
    LeaderAttribute[LeaderAttribute["All"] = 0] = "All";
    LeaderAttribute[LeaderAttribute["PrimaryRole"] = 1] = "PrimaryRole";
    LeaderAttribute[LeaderAttribute["SecondaryRole"] = 2] = "SecondaryRole";
    LeaderAttribute[LeaderAttribute["Level"] = 3] = "Level";
})(LeaderAttribute || (LeaderAttribute = {}));
export var prepareEightfoldDomain = function (domain) {
    if (domain) {
        domain = domain.toLowerCase();
        if (!domain.startsWith("http")) {
            domain = "https://".concat(domain);
        }
        if (domain.endsWith("/")) {
            // Remove the trailing "/"
            domain = domain.substring(0, domain.length - 1);
        }
    }
    return domain;
};
export var getAttributeType = function (valueIn) {
    var value = valueIn === null || valueIn === void 0 ? void 0 : valueIn.toUpperCase();
    return (value === null || value === void 0 ? void 0 : value[0]) == "L"
        ? LeaderAttribute.Level
        : (value === null || value === void 0 ? void 0 : value[0]) == "F"
            ? LeaderAttribute.PrimaryRole
            : (value === null || value === void 0 ? void 0 : value[0]) == "S"
                ? LeaderAttribute.SecondaryRole
                : LeaderAttribute.All;
};
var encode = function (value) {
    return encodeURIComponent(value).replaceAll("%20", "+");
};
export var getFilterParamString = function (filters) {
    var _a = filters || {}, location = _a.location, business_unit = _a.business_unit, ninebox_id = _a.ninebox_id, assigned_box = _a.assigned_box, level = _a.level, role = _a.role, manager = _a.manager, ethnicity = _a.ethnicity, gender = _a.gender, functionFilter = _a.functionFilter, subFunctionFilter = _a.subFunctionFilter;
    var result = [];
    if (location) {
        location.forEach(function (location) {
            result.push("location=".concat(encode(location)));
        });
    }
    if (assigned_box) {
        assigned_box.forEach(function (category) {
            result.push("assigned_box=".concat(encode(category)));
        });
    }
    if (ninebox_id && !ninebox_id.toLocaleLowerCase().startsWith("all")) {
        result.push("ninebox_id=".concat(encode(ninebox_id)));
    }
    if (ethnicity) {
        ethnicity.forEach(function (single) {
            result.push("ethnicity=".concat(encode(single)));
        });
    }
    if (gender) {
        gender.forEach(function (single) {
            result.push("gender=".concat(encode(single)));
        });
    }
    if (business_unit) {
        business_unit.forEach(function (businessUnit) {
            result.push("business_unit=".concat(encode(businessUnit)));
        });
    }
    if (functionFilter) {
        functionFilter.forEach(function (func) {
            result.push("function=".concat(encode(func)));
        });
    }
    if (subFunctionFilter) {
        subFunctionFilter.forEach(function (func) {
            result.push("sub_function=".concat(encode(func)));
        });
    }
    if (level) {
        if (typeof level == "string") {
            result.push("level=".concat(encode(level)));
        }
        if (typeof level == "object") {
            level.forEach(function (item) {
                result.push("level=".concat(encode(item)));
            });
        }
    }
    if (role && !role.toLocaleLowerCase().startsWith("all")) {
        if (getAttributeType(role) == LeaderAttribute.PrimaryRole) {
            result.push("function=".concat(encode(role)));
        }
        else if (getAttributeType(role) == LeaderAttribute.SecondaryRole) {
            result.push("sub_function=".concat(encode(role)));
        }
    }
    if (manager) {
        manager.forEach(function (item) {
            result.push("manager=".concat(encode(item)));
        });
    }
    return result.join("&");
};
export var getLeaderScoresParamString = function (filters) {
    var filterString = getFilterParamString(filters);
    var query = filterString ? [filterString] : [];
    return query.join("&");
};
var permissions;
export var useGetPermissions = function () {
    var _a = useState(undefined), innerPermissions = _a[0], setInnerPermissions = _a[1];
    var checkSettingPermissions = function () {
        if (permissions) {
            setInnerPermissions(permissions);
        }
        else {
            setTimeout(checkSettingPermissions, 50);
        }
    };
    if (!innerPermissions && innerPermissions !== null) {
        setInnerPermissions(null);
        setTimeout(checkSettingPermissions, 50);
    }
    return innerPermissions;
};
var getParamString = function (filters, leaderIdQuery, factor_id) {
    var filterString = getFilterParamString(filters);
    var query = filterString ? [filterString] : [];
    if (leaderIdQuery) {
        query.push("leader_id=".concat(encodeURIComponent(leaderIdQuery)));
    }
    if (factor_id && factor_id.toLocaleLowerCase() != "all") {
        query.push("factor_id=".concat(encodeURIComponent(factor_id)));
    }
    return query.join("&");
};
var tokenFetchPromise;
export var getClientId = function () {
    return localStorage.getItem("clientId");
};
var getHeaders = function () { return __awaiter(void 0, void 0, void 0, function () {
    var headers, authToken, now, tokenCheck, origin, clientId, authZUrl_1, token, e_1, authZToken;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                headers = {};
                authToken = (_c = (_b = JSON.parse((_a = localStorage.getItem("okta-token-storage")) !== null && _a !== void 0 ? _a : '{"accessToken": {"accessToken": ""}}')) === null || _b === void 0 ? void 0 : _b.accessToken) === null || _c === void 0 ? void 0 : _c.accessToken;
                if (authToken) {
                    headers.authorization = "Bearer ".concat(authToken);
                }
                if (!isLiveEnvironment()) return [3 /*break*/, 5];
                now = new Date().getTime();
                tokenCheck = localStorage.getItem("X-AUTH-Z");
                if (!(authToken &&
                    (!permissions || !tokenCheck || now > JSON.parse(tokenCheck).expires))) return [3 /*break*/, 4];
                origin = apiBase === null || apiBase === void 0 ? void 0 : apiBase.platform;
                clientId = getClientId() || "";
                authZUrl_1 = "".concat(origin, "/auth/api/v2/me?pac-applications=ORG_DASHBOARD_API,ORG_DASHBOARD_UI,PLATFORM_API&generateAuthZ=true&pac-clientId=").concat(clientId);
                _e.label = 1;
            case 1:
                _e.trys.push([1, 3, , 4]);
                if (!tokenFetchPromise) {
                    tokenFetchPromise = (function () { return __awaiter(void 0, void 0, void 0, function () {
                        var response, tokenPayload, personaIdToClientId, _i, permissions_1, permission, jwt;
                        var _a, _b, _c, _d;
                        return __generator(this, function (_e) {
                            switch (_e.label) {
                                case 0: return [4 /*yield*/, fetch(authZUrl_1, { method: "GET", headers: headers })];
                                case 1:
                                    response = _e.sent();
                                    return [4 /*yield*/, response.json()];
                                case 2:
                                    tokenPayload = _e.sent();
                                    permissions = (_a = tokenPayload === null || tokenPayload === void 0 ? void 0 : tokenPayload.data) === null || _a === void 0 ? void 0 : _a.permissions;
                                    personaIdToClientId = (_b = tokenPayload === null || tokenPayload === void 0 ? void 0 : tokenPayload.data) === null || _b === void 0 ? void 0 : _b.myPersonas.reduce(function (result, persona) {
                                        result[persona.personaId] = persona.clientId || "*";
                                        return result;
                                    }, {});
                                    for (_i = 0, permissions_1 = permissions; _i < permissions_1.length; _i++) {
                                        permission = permissions_1[_i];
                                        if (permission.resourceName == "ORG_DASHBOARD_UI") {
                                            permission.clientIds = (_c = permission.personaIds) === null || _c === void 0 ? void 0 : _c.map(function (personaId) { return personaIdToClientId[personaId] || "*"; });
                                        }
                                        delete permission.personaIds;
                                    }
                                    jwt = (_d = tokenPayload === null || tokenPayload === void 0 ? void 0 : tokenPayload.data) === null || _d === void 0 ? void 0 : _d.jwt;
                                    if (!permissions) {
                                        throw "did not receive permissions";
                                    }
                                    if (!jwt) {
                                        throw "did not receive jwt token";
                                    }
                                    return [2 /*return*/, jwt];
                            }
                        });
                    }); })();
                }
                return [4 /*yield*/, tokenFetchPromise];
            case 2:
                token = _e.sent();
                tokenFetchPromise = undefined;
                localStorage.setItem("X-AUTH-Z", JSON.stringify({
                    token: token,
                    expires: now + 1000 * 60 * 50,
                }));
                return [3 /*break*/, 4];
            case 3:
                e_1 = _e.sent();
                tokenFetchPromise = undefined;
                console.error("error reading X-AUTH-Z token from server");
                return [3 /*break*/, 4];
            case 4:
                authZToken = (_d = JSON.parse(localStorage.getItem("X-AUTH-Z") || "{}")) === null || _d === void 0 ? void 0 : _d.token;
                if (authZToken) {
                    headers["X-AUTH-Z"] = authZToken;
                }
                _e.label = 5;
            case 5: return [2 /*return*/, headers];
        }
    });
}); };
var sendRequest = function (_a) {
    var url = _a.url, method = _a.method, body = _a.body;
    return __awaiter(void 0, void 0, void 0, function () {
        var headers, response, requestParams, error_1, _b, _c;
        var _d;
        var _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0: return [4 /*yield*/, getHeaders()];
                case 1:
                    headers = _f.sent();
                    requestParams = {
                        method: method,
                        headers: headers,
                    };
                    if (body) {
                        requestParams.headers["Content-Type"] = "application/json";
                        requestParams["body"] = JSON.stringify(body);
                    }
                    _f.label = 2;
                case 2:
                    _f.trys.push([2, 4, 5, 6]);
                    incrementSpinReason();
                    return [4 /*yield*/, fetch("".concat(url), requestParams)];
                case 3:
                    response = _f.sent();
                    return [3 /*break*/, 6];
                case 4:
                    error_1 = _f.sent();
                    throw new Error("444");
                case 5:
                    decrementSpinReason();
                    return [7 /*endfinally*/];
                case 6:
                    if (!!response.ok) return [3 /*break*/, 8];
                    _b = Error.bind;
                    _c = [void 0, "".concat(response.status)];
                    _d = {};
                    return [4 /*yield*/, response.json()];
                case 7: throw new (_b.apply(Error, _c.concat([(_d.cause = _f.sent(), _d)])))();
                case 8:
                    if (!((_e = response.headers.get("content-type")) === null || _e === void 0 ? void 0 : _e.includes("text/csv"))) return [3 /*break*/, 10];
                    return [4 /*yield*/, response.text()];
                case 9: return [2 /*return*/, _f.sent()];
                case 10: return [4 /*yield*/, response.json()];
                case 11: return [2 /*return*/, _f.sent()];
            }
        });
    });
};
function doEncoding(key, value) {
    return Object.keys(apiBase).includes(key) ? value : encodeURIComponent(value);
}
function getEndpointCall(_a) {
    var _this = this;
    var endpoint = _a.endpoint, getParams = _a.getParams, selectCallback = _a.select;
    return function (query, options) {
        var shouldSkipCall = (options === null || options === void 0 ? void 0 : options.shouldSkipCall) || (function () { return false; });
        var params = getParams(query || {});
        var paramsType = typeof (params || {}).asString == "string" &&
            typeof (params || {}).asObject == "object"
            ? params
            : typeof params == "string"
                ? { asString: params, asObject: {} }
                : { asString: "", asObject: params || {} };
        var key = Object.entries(__assign({}, paramsType.asObject)).reduce(function (current, _a) {
            var key = _a[0], value = _a[1];
            if (!endpoint.includes("{".concat(key, "}"))) {
                return current;
            }
            return current.replace("{".concat(key, "}"), doEncoding(key, value));
        }, endpoint);
        var substitutedEndpoint = Object.entries(__assign(__assign({}, paramsType.asObject), apiBase)).reduce(function (current, _a) {
            var key = _a[0], value = _a[1];
            if (!endpoint.includes("{".concat(key, "}"))) {
                return current;
            }
            delete paramsType.asObject[key];
            return current.replace("{".concat(key, "}"), doEncoding(key, value));
        }, endpoint.replace("{ClientId}", getClientId() || ""));
        var paramsLead = endpoint.includes("?") ? "&" : "?";
        var paramsFromObject = Object.entries(paramsType.asObject).reduce(function (current, _a) {
            var key = _a[0], value = _a[1];
            return "".concat(current).concat(current.length != 0 ? "&" : paramsLead).concat(key, "=").concat(encodeURIComponent(value));
        }, "");
        var paramsStringSection = paramsFromObject && paramsType.asString
            ? [paramsFromObject, paramsType.asString].join("&")
            : paramsFromObject || paramsType.asString;
        var paramString = "".concat(paramsStringSection ? paramsLead : "").concat(paramsStringSection);
        var url = "".concat(substitutedEndpoint).concat(paramString);
        // Get the query params as an object as expected by react query
        var paramObject = paramString
            ? Object.fromEntries(new URLSearchParams(paramString))
            : {};
        if (key.includes("{ClientId}")) {
            // Remove path client ID from the key to make it easier to invalidate
            paramObject.clientId = getClientId() || "";
        }
        return useQuery(__assign(__assign({ placeholderData: keepPreviousData }, (isLiveEnvironment()
            ? {
                refetchOnWindowFocus: false,
            }
            : { retry: false })), { queryKey: [key, paramObject, paramString], queryFn: function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!!shouldSkipCall()) return [3 /*break*/, 2];
                            return [4 /*yield*/, sendRequest({ url: url, method: "GET" })];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2: return [2 /*return*/];
                    }
                });
            }); }, select: function (data) {
                return selectCallback(data);
            } }));
    };
}
var mutableEndpointCall = function (endpoint, method) {
    return function (_a) {
        var invalidates = _a.invalidates, substitutions = _a.substitutions, events = _a.events;
        return useMutation({
            mutationFn: function (body) { return __awaiter(void 0, void 0, void 0, function () {
                var newEndpoint;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            newEndpoint = Object.entries(__assign(__assign({}, substitutions), apiBase) || {}).reduce(function (result, _a) {
                                var key = _a[0], value = _a[1];
                                return result.replace("{".concat(key, "}"), value);
                            }, endpoint.replace("{ClientId}", getClientId() || ""));
                            if (!isStorybookEnvironment()) return [3 /*break*/, 1];
                            // When in Storybook, just log the output
                            console.log("".concat(method, " \"").concat(newEndpoint, "\": ").concat(JSON.stringify(body, null, 2)));
                            return [3 /*break*/, 3];
                        case 1: return [4 /*yield*/, sendRequest({
                                url: newEndpoint,
                                method: method,
                                body: body,
                            })];
                        case 2: return [2 /*return*/, _a.sent()];
                        case 3: return [2 /*return*/];
                    }
                });
            }); },
            onError: (events === null || events === void 0 ? void 0 : events.onError)
                ? function () {
                    events.onError();
                }
                : function () { return undefined; },
            onSuccess: function () {
                (function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!invalidates) return [3 /*break*/, 2];
                                return [4 /*yield*/, Promise.all(invalidates.map(function (invalidator) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, queryClient.invalidateQueries(invalidator)];
                                            case 1: return [2 /*return*/, _a.sent()];
                                        }
                                    }); }); }))];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2:
                                (events === null || events === void 0 ? void 0 : events.onSuccess) && (events === null || events === void 0 ? void 0 : events.onSuccess());
                                return [2 /*return*/];
                        }
                    });
                }); })();
            },
        });
    };
};
var createEndpointCall = function (endpoint) {
    return mutableEndpointCall(endpoint, "POST");
};
var updateEndpointCall = function (endpoint) {
    return mutableEndpointCall(endpoint, "PATCH");
};
var deleteEndpointCall = function (endpoint) {
    return mutableEndpointCall(endpoint, "DELETE");
};
var putEndpointCall = function (endpoint) {
    return mutableEndpointCall(endpoint, "PUT");
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
var selectData = function (transform) {
    return function (_a) {
        var data = _a.data;
        return transform(data);
    };
};
/* eslint-enable  @typescript-eslint/no-explicit-any */
var processOverviewData = function (data) {
    var primaryFunctions = data === null || data === void 0 ? void 0 : data.functions.map(function (_a) {
        var function_id = _a.function_id;
        return function_id;
    });
    var lookupCodeMap = __assign(__assign(__assign({ "All Functions": "All Functions", "All Levels": "All Levels", ALL: "All", "": "All", all: "All", All: "All" }, data === null || data === void 0 ? void 0 : data.levels.reduce(function (result, level) {
        var _a;
        return Object.assign(result, (_a = {}, _a[level.level_id] = level.level_name, _a));
    }, {})), data === null || data === void 0 ? void 0 : data.functions.reduce(function (result, function_obj) {
        var _a;
        return Object.assign(result, (_a = {},
            _a[function_obj.function_id] = function_obj.function_name,
            _a));
    }, {})), data === null || data === void 0 ? void 0 : data.sub_functions.reduce(function (result, subFunction) {
        var _a;
        return Object.assign(result, (_a = {},
            _a[subFunction.sub_function_id] = subFunction.sub_function_name,
            _a));
    }, {}));
    var subFunctionsToFunctionsMap = data === null || data === void 0 ? void 0 : data.sub_functions.reduce(function (current, _a) {
        var _b;
        var function_id = _a.function_id, sub_function_id = _a.sub_function_id;
        return Object.assign(current, (_b = {}, _b[sub_function_id] = function_id, _b));
    }, {
        "": "All",
        all: "All",
        All: "All",
    });
    var codeTypeMap = __assign(__assign(__assign({}, data === null || data === void 0 ? void 0 : data.levels.reduce(function (result, level) {
        var _a;
        return Object.assign(result, (_a = {}, _a[level.level_id] = LEVEL, _a));
    }, {})), data === null || data === void 0 ? void 0 : data.functions.reduce(function (result, function_obj) {
        var _a;
        return Object.assign(result, (_a = {},
            _a[function_obj.function_id] = FUNCTION,
            _a));
    }, {})), data === null || data === void 0 ? void 0 : data.sub_functions.reduce(function (result, subFunction) {
        var _a;
        return Object.assign(result, (_a = {},
            _a[subFunction.sub_function_id] = SUB_FUNCTION,
            _a));
    }, {}));
    var indexForLevelId = reverse(sortBy(data === null || data === void 0 ? void 0 : data.levels, "level_id")).reduce(function (result, _a, index) {
        var level_id = _a.level_id;
        result[level_id] = index + 1;
        return result;
    }, {});
    var subFunctionsForFunctions = (data === null || data === void 0 ? void 0 : data.sub_functions.reduce(function (result, element) {
        if (!result[element.function_id]) {
            result[element.function_id] = [];
        }
        result[element.function_id].push(element);
        return result;
    }, {})) || {};
    var dataBreakdownHeaders = (data === null || data === void 0 ? void 0 : data.functions.reduce(function (result, _a) {
        var function_id = _a.function_id;
        var headers = subFunctionsForFunctions[function_id].map(function (_a) {
            var sub_function_id = _a.sub_function_id;
            return sub_function_id;
        });
        result[function_id] = { headers: headers, data: [] };
        return result;
    }, {})) || {};
    var emptyFunctionIndexes = (data === null || data === void 0 ? void 0 : data.functions.reduce(function (result, _a, functionIndex) {
        var function_id = _a.function_id;
        result[function_id] = {
            functionIndex: functionIndex + 1,
            functionName: function_id,
            subFunctions: { ALL: 0 },
        };
        return result;
    }, {})) || {};
    emptyFunctionIndexes["ALL"] = {
        functionIndex: 0,
        functionName: "All Functions",
        subFunctions: {},
    };
    Object.values(dataBreakdownHeaders).forEach(function (subFunction) {
        subFunction.headers = sortBy(subFunction.headers, function (value) { return lookupCodeMap[value]; });
    });
    var sortedPrimaryFunctions = sortBy(primaryFunctions, function (value) { return lookupCodeMap[value]; });
    var functionIndexes = {
        ALL: {
            functionIndex: 0,
            functionName: "All Functions",
            subFunctions: {},
        },
    };
    sortedPrimaryFunctions.forEach(function (functionName, index) {
        var subFunctions = {
            ALL: 0,
        };
        dataBreakdownHeaders[functionName].headers.forEach(function (value, index) {
            subFunctions[value] = index + 1;
        });
        functionIndexes[functionName] = {
            functionName: functionName,
            functionIndex: index + 1,
            subFunctions: subFunctions,
        };
    });
    return {
        data: data,
        functionIndexes: functionIndexes,
        dataBreakdownHeaders: dataBreakdownHeaders,
        indexForLevelId: indexForLevelId,
        primaryFunctions: sortedPrimaryFunctions,
        lookupCodeMap: lookupCodeMap,
        subFunctionsToFunctionsMap: subFunctionsToFunctionsMap,
        codeTypeMap: codeTypeMap,
    };
};
// ******************** Platform APIs ********************
export var useGetClientListPlatformQuery = getEndpointCall({
    endpoint: "{platform}/clients/api/v2/clients",
    getParams: function (_a) {
        var navigatorUrl = _a.navigatorUrl;
        return navigatorUrl
            ? "navigatorApplicationUrl=".concat(encodeURIComponent(navigatorUrl))
            : "";
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
export var useGetClientPlatformQuery = getEndpointCall({
    endpoint: "{platform}/clients/api/v2/clients/{ClientId}",
    getParams: function () { return ""; },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
export var useGetRoleProfileListQuery = getEndpointCall({
    endpoint: "{platform}/leadership/api/v2/role-profiles",
    getParams: function (_a) {
        var clientId = _a.clientId, limit = _a.limit, offset = _a.offset, sort = _a.sort;
        var params = "clientId=".concat(encodeURIComponent(clientId || "Master"));
        if (Number.isInteger(limit) && Number.isInteger(offset)) {
            params += "&limit=".concat(encodeURIComponent(limit), "&offset=").concat(encodeURIComponent(offset));
        }
        if (sort && sort.length > 0) {
            var sortParams = sort.map(function (sortItem) { return encodeURIComponent(sortItem); });
            params += "&sort=".concat(sortParams.join(","));
        }
        return params;
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
export var useGetSkillsCategoryQuery = getEndpointCall({
    endpoint: "{platform}/leadership/api/v2/skill-categories",
    getParams: function (_a) {
        var clientId = _a.clientId, roleProfileId = _a.roleProfileId, levelCode = _a.levelCode;
        var params = "clientId=".concat(encodeURIComponent(clientId || "Master"));
        if (roleProfileId) {
            params += "&roleProfileId=".concat(encodeURIComponent(roleProfileId));
        }
        if (levelCode) {
            params += "&levelCode=".concat(encodeURIComponent(levelCode));
        }
        return params;
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
export var useGetStatementLibrariesQuery = getEndpointCall({
    endpoint: "{platform}/leadership/api/v2/statement-libraries",
    getParams: function (_a) {
        var clientId = _a.clientId, skillGroup = _a.skillGroup;
        return "clientId=".concat(encodeURIComponent(clientId || "Master"), "&skillGroup=").concat(encodeURIComponent(skillGroup));
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
export var useGetLookupsQuery = getEndpointCall({
    endpoint: "{platform}/leadership/api/v2/lookups",
    getParams: function (_a) {
        var clientId = _a.clientId;
        return "clientId=".concat(encodeURIComponent(clientId || "Master"), "&lookUpTypesList=Level,Importance Rating,Impact Module");
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
export var useGetClientModelsQuery = getEndpointCall({
    endpoint: "{platform}/leadership/api/v2/client-models",
    getParams: function (_a) {
        var clientId = _a.clientId, stageGroup = _a.stageGroup, skillGroupName = _a.skillGroupName;
        var parameter = stageGroup
            ? "&stageGroup=".concat(encodeURIComponent(stageGroup))
            : "&skillGroupName=".concat(encodeURIComponent(skillGroupName));
        return "clientId=".concat(encodeURIComponent(clientId || "Master")).concat(parameter);
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
export var useSuccessProfileDownloadReport = createEndpointCall("{platform}/assessment-reports/v2/generator");
// ******************** General APIs ********************
export var useGetLeaderFiltersQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/filters",
    getParams: function (_a) {
        var filters = _a.filters;
        return getFilterParamString(filters);
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
export var useGetClientDetailsQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/client-details",
    getParams: function () { return ""; },
    select: function (data) { return data; },
});
export var updateClientDetailsQuery = updateEndpointCall("{dashboard}/{ClientId}/client-details");
export var useGetUserDetailsQuery = getEndpointCall({
    endpoint: "{dashboard}/user-details",
    getParams: function () { return ""; },
    select: selectData(function (data) { return data; }),
});
// ******************** Leadership Summary APIs ********************
export var useGetLeadershipOverviewQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leadership/overview",
    getParams: function () { return ""; },
    select: selectData(processOverviewData),
});
export var useGetLeadershipScoresQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leadership/scores",
    getParams: function (_a) {
        var filters = _a.filters;
        return getFilterParamString(filters);
    },
    select: selectData(function (data) {
        var transformed = transformScores({
            fillEmpty: function () { return ({ state: "nodata" }); },
        })(data);
        return transformed;
    }),
});
export var useGetLeadershipRisksQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leadership/risks",
    getParams: function (_a) {
        var filters = _a.filters, scoreType = _a.scoreType, role = _a.role;
        if (filters) {
            var params = [
                "score_type=".concat(normalizeScoreType(scoreType || (filters === null || filters === void 0 ? void 0 : filters.scoreType))),
            ];
            var paramString = getParamString(filters);
            if (paramString) {
                params.push(paramString);
            }
            return params.join("&");
        }
        var otherParams = getParamString({ locations: [], role: role });
        if (otherParams) {
            return [
                otherParams,
                "score_type=".concat(normalizeScoreType(scoreType || (filters === null || filters === void 0 ? void 0 : filters.scoreType))),
            ].join("&");
        }
        return "score_type=".concat(normalizeScoreType(scoreType || (filters === null || filters === void 0 ? void 0 : filters.scoreType)));
    },
    select: selectData(function (data) {
        var getRiskyLeader = function (id) {
            return data === null || data === void 0 ? void 0 : data.high_risk_entities.find(function (_a) {
                var leader_id = _a.leader_id;
                return leader_id == id;
            });
        };
        return { data: data, getRiskyLeader: getRiskyLeader };
    }),
});
export var useGetLeadershipThresholdsQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leadership/threshold",
    getParams: function (_a) {
        var scoreType = _a.scoreType;
        return "score_type=".concat(normalizeScoreType(scoreType));
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
export var useGetLeadersLowScoreThresholdsQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leadership/low-score-threshold",
    getParams: function (_a) {
        var scoreType = _a.scoreType;
        return "score_type=".concat(normalizeScoreType(scoreType));
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
export var useGetScoreStateQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leadership/state",
    getParams: function (_a) {
        var score = _a.score, scoreType = _a.scoreType;
        return "score=".concat(score, "&score_type=").concat(encodeURIComponent(normalizeScoreType(scoreType)));
    },
    select: selectData(function (data) { return data; }),
});
export var useGetLeaderScoresQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leadership/leader-scores",
    getParams: function (_a) {
        var filters = _a.filters;
        return getLeaderScoresParamString(filters);
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
// ******************** Leaders at Risk APIs ********************
export var useGetLeaderAtRiskOverviewQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leadership/leaders-at-risk/overview",
    getParams: function () { return ""; },
    select: selectData(processOverviewData),
});
export var useGetLeaderAtRiskScoresQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leadership/leaders-at-risk/scores",
    getParams: function (_a) {
        var filters = _a.filters;
        return getFilterParamString(filters);
    },
    select: selectData(function (data) {
        var transformed = transformScores({
            fillEmpty: function () { return ({ state: "nodata" }); },
        })(data);
        return transformed;
    }),
});
export var useGetLeadersAtRiskThresholdsQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leadership/leaders-at-risk/threshold",
    getParams: function (_a) {
        var scoreType = _a.scoreType;
        return "score_type=".concat(normalizeScoreType(scoreType));
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
export var useGetLeadersAtRiskRisksQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leadership/leaders-at-risk/risks",
    getParams: function (_a) {
        var filters = _a.filters, scoreType = _a.scoreType, role = _a.role;
        if (filters) {
            var params = [
                "score_type=".concat(normalizeScoreType(scoreType || (filters === null || filters === void 0 ? void 0 : filters.scoreType))),
            ];
            var paramString = getParamString(filters);
            if (paramString) {
                params.push(paramString);
            }
            return params.join("&");
        }
        var otherParams = getParamString({ locations: [], role: role });
        if (otherParams) {
            return [
                otherParams,
                "score_type=".concat(normalizeScoreType(scoreType || (filters === null || filters === void 0 ? void 0 : filters.scoreType))),
            ].join("&");
        }
        return "score_type=".concat(normalizeScoreType(scoreType || (filters === null || filters === void 0 ? void 0 : filters.scoreType)));
    },
    select: selectData(function (data) {
        var getRiskyLeader = function (id) {
            return data === null || data === void 0 ? void 0 : data.high_risk_entities.find(function (_a) {
                var leader_id = _a.leader_id;
                return leader_id == id;
            });
        };
        return { data: data, getRiskyLeader: getRiskyLeader };
    }),
});
// ******************** Position Succession APIs ********************
export var useGetPositionSuccessionOverviewQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/position-succession/overview",
    getParams: function () { return ""; },
    select: selectData(processOverviewData),
});
export var useGetPositionSuccessionScoresQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/position-succession/scores",
    getParams: function (_a) {
        var filters = _a.filters;
        return getFilterParamString(filters);
    },
    select: selectData(function (data) {
        var transformed = transformScores({
            fillEmpty: function () { return ({ state: "nodata" }); },
        })(data);
        return transformed;
    }),
});
export var useGetPositionSuccessionRisksQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/position-succession/risks",
    getParams: function (_a) {
        var filters = _a.filters, scoreType = _a.scoreType, role = _a.role;
        if (filters) {
            var params = [
                "score_type=".concat(normalizeScoreType(scoreType || (filters === null || filters === void 0 ? void 0 : filters.scoreType))),
            ];
            var paramString = getParamString(filters);
            if (paramString) {
                params.push(paramString);
            }
            return params.join("&");
        }
        var otherParams = getParamString({ locations: [], role: role });
        if (otherParams) {
            return [
                otherParams,
                "score_type=".concat(normalizeScoreType(scoreType || (filters === null || filters === void 0 ? void 0 : filters.scoreType))),
            ].join("&");
        }
        return "score_type=".concat(normalizeScoreType(scoreType || (filters === null || filters === void 0 ? void 0 : filters.scoreType)));
    },
    select: selectData(function (data) {
        var getRiskyLeader = function (id) {
            return data === null || data === void 0 ? void 0 : data.high_risk_entities.find(function (_a) {
                var leader_id = _a.leader_id;
                return leader_id == id;
            });
        };
        return { data: data, getRiskyLeader: getRiskyLeader };
    }),
});
export var useGetPositionSuccessionThresholdsQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/position-succession/threshold",
    getParams: function (_a) {
        var scoreType = _a.scoreType;
        return "score_type=".concat(normalizeScoreType(scoreType));
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
export var useGetPositionedSuccessionLeaderScoresQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/position-succession/leader-scores",
    getParams: function (_a) {
        var filters = _a.filters;
        return getLeaderScoresParamString(filters);
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
export var useGetPositionedRecommendedSuccessors = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/position-succession/{leaderId}/recommended-successors",
    getParams: function (_a) {
        var leaderId = _a.leaderId;
        return ({ leaderId: leaderId });
    },
    select: selectData(function (data) { return data; }),
});
// ******************** Leader Succession APIs ********************
export var useGetLeaderedSuccessionOverviewQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leader-succession/overview",
    getParams: function () { return ""; },
    select: selectData(processOverviewData),
});
export var useGetLeaderedSuccessionScoresQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leader-succession/scores",
    getParams: function (_a) {
        var filters = _a.filters;
        return getFilterParamString(filters);
    },
    select: selectData(function (data) {
        var transformed = transformScores({
            fillEmpty: function () { return ({ state: "nodata" }); },
        })(data);
        return transformed;
    }),
});
export var useGetLeaderedSuccessionRisksQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leader-succession/risks",
    getParams: function (_a) {
        var filters = _a.filters, scoreType = _a.scoreType, role = _a.role;
        if (filters) {
            var params = [
                "score_type=".concat(normalizeScoreType(scoreType || (filters === null || filters === void 0 ? void 0 : filters.scoreType))),
            ];
            var paramString = getParamString(filters);
            if (paramString) {
                params.push(paramString);
            }
            return params.join("&");
        }
        var otherParams = getParamString({ locations: [], role: role });
        if (otherParams) {
            return [
                otherParams,
                "score_type=".concat(normalizeScoreType(scoreType || (filters === null || filters === void 0 ? void 0 : filters.scoreType))),
            ].join("&");
        }
        return "score_type=".concat(normalizeScoreType(scoreType || (filters === null || filters === void 0 ? void 0 : filters.scoreType)));
    },
    select: selectData(function (data) {
        var getRiskyLeader = function (id) {
            return data === null || data === void 0 ? void 0 : data.high_risk_entities.find(function (_a) {
                var leader_id = _a.leader_id;
                return leader_id == id;
            });
        };
        return { data: data, getRiskyLeader: getRiskyLeader };
    }),
});
export var useGetLeaderedSuccessionThresholdsQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leader-succession/threshold",
    getParams: function (_a) {
        var scoreType = _a.scoreType;
        return "score_type=".concat(normalizeScoreType(scoreType));
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
export var useGetLeaderedSuccessionLeaderScoresQuery = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leader-succession/leader-scores",
    getParams: function (_a) {
        var filters = _a.filters;
        return getLeaderScoresParamString(filters);
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
// ******************** Leader Diversity APIs   ********************
export var useGetDiversityLeaders = getEndpointCall({
    endpoint: "{dashboard}/{ClientId}/leaders",
    getParams: function (_a) {
        var filters = _a.filters;
        var filterParams = getLeaderScoresParamString(__assign({}, filters));
        return filterParams
            ? "".concat(filterParams, "&data_model=diversity")
            : "data_model=diversity";
    },
    select: function (_a) {
        var data = _a.data;
        return ({ data: data });
    },
});
// ******************** Nine Box ********************
var getNineBoxThresholds = function (full) {
    var parts = full.split("-").map(Number);
    return [parts[0], parts[1]];
};
var axisFromData = function (_a) {
    var dataSources = _a.dataSources, axisLabel = _a.axisLabel, lowLabel = _a.lowLabel, midLabel = _a.midLabel, highLabel = _a.highLabel, lowThreshold = _a.lowThreshold, midThreshold = _a.midThreshold, highThreshold = _a.highThreshold, lowDescription = _a.lowDescription, midDescription = _a.midDescription, highDescription = _a.highDescription;
    return ({
        dataSources: dataSources,
        axisLabel: axisLabel,
        lowLabel: lowLabel,
        middleLabel: midLabel,
        highLabel: highLabel,
        lowThreshold: getNineBoxThresholds(lowThreshold),
        middleThreshold: getNineBoxThresholds(midThreshold),
        highThreshold: getNineBoxThresholds(highThreshold),
        lowDescription: lowDescription,
        middleDescription: midDescription,
        highDescription: highDescription,
    });
};
var normalizeBoxes = function (data) { return [
    data.boxOne,
    data.boxTwo,
    data.boxThree,
    data.boxFour,
    data.boxFive,
    data.boxSix,
    data.boxSeven,
    data.boxEight,
    data.boxNine,
]; };
var getBoxLabels = function (data) { return function (box) {
    var _a;
    if (!data) {
        return { xName: "", yName: "", xPlace: "", yPlace: "", name: "" };
    }
    var xAxis = data.xAxis, yAxis = data.yAxis;
    var name = (_a = normalizeBoxes(data)[box]) === null || _a === void 0 ? void 0 : _a.name;
    var xName = xAxis.axisLabel;
    var yName = yAxis.axisLabel;
    var xDataSources = xAxis.dataSources;
    var yDataSources = yAxis.dataSources;
    var xPlace = box < 3 ? xAxis.lowLabel : box < 6 ? xAxis.midLabel : xAxis.highLabel;
    var modulus = box % 3;
    var yPlace = modulus == 0
        ? yAxis.lowLabel
        : modulus == 1
            ? yAxis.midLabel
            : yAxis.highLabel;
    return { xName: xName, yName: yName, xPlace: xPlace, yPlace: yPlace, name: name, xDataSources: xDataSources, yDataSources: yDataSources };
}; };
export var AxisDataSource;
(function (AxisDataSource) {
    AxisDataSource["LeadershipTrajectory"] = "Potential,Capabilities";
    AxisDataSource["Performance"] = "Performance";
})(AxisDataSource || (AxisDataSource = {}));
var getAxisByDataSource = function (data) { return function (dataSource) {
    if (data.xAxis.dataSources.includes(dataSource)) {
        return data.xAxis;
    }
    if (data.yAxis.dataSources.includes(dataSource)) {
        return data.yAxis;
    }
}; };
var getScores = function (boxLeader) { return function (scoreType) {
    var _a;
    var score = ((_a = boxLeader === null || boxLeader === void 0 ? void 0 : boxLeader.leaderScores.find(function (_a) {
        var name = _a.name;
        return name == scoreType;
    })) === null || _a === void 0 ? void 0 : _a.score) || 0;
    var categoricalResult = "Developing";
    if (score >= 5) {
        categoricalResult = "Expert";
    }
    else if (score >= 4) {
        categoricalResult = "Advanced";
    }
    else if (score >= 3) {
        categoricalResult = "Proficient";
    }
    else if (score >= 2) {
        categoricalResult = "Emerging";
    }
    return {
        score: score,
        categoricalResult: categoricalResult,
    };
}; };
var transformBoxLeader = function (data) { return function (original) {
    var _a;
    var _b, _c;
    return (__assign(__assign({}, original), { recommendedBox: ((original === null || original === void 0 ? void 0 : original.recommendedBox) || 0) - 1, assignedBox: ((original === null || original === void 0 ? void 0 : original.assignedBox) || 0) - 1, movedTs: new Date(original.movedTs), timeInRoleYears: ((original.timeInRole || 0) / 60 / 60 / 24 / 365).toFixed(1), lastUpdatedTs: new Date(original.lastUpdatedTs), getRecommendedLabels: function () {
            return getBoxLabels(data)(((original === null || original === void 0 ? void 0 : original.recommendedBox) || 0) - 1);
        }, getAssignedLabels: function () { return getBoxLabels(data)(((original === null || original === void 0 ? void 0 : original.assignedBox) || 0) - 1); }, getTrajectoryMax: function () {
            return getNineBoxThresholds(getAxisByDataSource(data)(AxisDataSource.LeadershipTrajectory)
                .highThreshold)[1];
        }, trajectoryScores: (_a = {},
            _a[BoxLeaderScoreType.Potential] = getScores(original)(BoxLeaderScoreType.Potential),
            _a[BoxLeaderScoreType.Capabilities] = getScores(original)(BoxLeaderScoreType.Capabilities),
            _a), getPerformanceMax: function () {
            return getNineBoxThresholds(getAxisByDataSource(data)(AxisDataSource.Performance).highThreshold)[1];
        }, performanceScore: ((_b = original === null || original === void 0 ? void 0 : original.leaderScores.filter(function (_a) {
            var name = _a.name;
            return name == BoxLeaderScoreType.Performance;
        })[0]) === null || _b === void 0 ? void 0 : _b.score) || 0, performanceRatings: (_c = original === null || original === void 0 ? void 0 : original.performanceRatings) === null || _c === void 0 ? void 0 : _c.sort(function (a, b) {
            if (a.endDate < b.endDate) {
                return 1;
            }
            else if (a.endDate > b.endDate) {
                return -1;
            }
            return 0;
        }).map(function (rating) { return (__assign(__assign({}, rating), { endDate: new Date(rating.endDate) })); }) }));
}; };
export var useGetAvailableNineBoxes = getEndpointCall({
    endpoint: "{ninebox}/nine-box?clientId={ClientId}",
    getParams: function (_a) {
        var boxId = _a.boxId;
        return (boxId ? "boxId=".concat(boxId) : "");
    },
    select: function (_a) {
        var data = _a.data;
        return ({
            data: data
                .filter(function (_a) {
                var boxId = _a.boxId;
                return boxId != "template";
            })
                .map(function (box) { return (__assign(__assign({}, box), { createdTs: new Date(box.createdTs), lastUpdatedTs: new Date(box.lastUpdatedTs) })); }),
        });
    },
});
export var useGetFilteredNineBox = function (_a) {
    var id = _a.id, assigned_box = _a.assigned_box;
    var getFilters = useContext(FilterContext).getFilters;
    var ninebox = useGetNineBox({ id: id, assigned_box: assigned_box, filters: getFilters() });
    return ninebox;
};
export var useGetNineBox = getEndpointCall({
    endpoint: "{ninebox}/nine-box/{id}",
    getParams: function (_a) {
        var filters = _a.filters, id = _a.id, assigned_box = _a.assigned_box;
        // Avoid conflicts with global assigned_box filter
        var filterString = getLeaderScoresParamString(__assign(__assign({}, filters), { assigned_box: [] }));
        return {
            asString: "".concat(filterString).concat(assigned_box ? "&assigned_box=" + encode(assigned_box) : ""),
            asObject: { id: id },
        };
    },
    select: function (_a) {
        var data = _a.data;
        return ({
            data: {
                id: data.id,
                clientId: data.clientId,
                createUser: __assign({}, data.createUser),
                boxId: data.boxId,
                boxName: data.boxName,
                version: data.version,
                versionName: data.versionName,
                filters: __assign({}, data.filters),
                xAxis: axisFromData(data.xAxis),
                yAxis: axisFromData(data.yAxis),
                boxes: normalizeBoxes(data),
                boxByIndex: function (index) {
                    return ({
                        1: data.boxOne,
                        2: data.boxTwo,
                        3: data.boxThree,
                        4: data.boxFour,
                        5: data.boxFive,
                        6: data.boxSix,
                        7: data.boxSeven,
                        8: data.boxEight,
                        9: data.boxNine,
                    }[index]);
                },
                leaders: data.boxLeaders.map(transformBoxLeader(data)),
                createdTs: new Date(data.createdTs),
                lastUpdatedTs: new Date(data.lastUpdatedTs),
                updateUser: data.updateUser,
            },
        });
    },
});
export var useGetNineBoxCategoriesFilter = getEndpointCall({
    endpoint: "{ninebox}/nine-box/{id}/categories",
    getParams: function (_a) {
        var filters = _a.filters, id = _a.id;
        var filterString = getLeaderScoresParamString(filters);
        return {
            asString: filterString,
            asObject: { id: id },
        };
    },
    select: function (_a) {
        var data = _a.data;
        return ({
            data: {
                boxes: [
                    __assign(__assign({}, data[0].boxOne), { assignedId: 1 }),
                    __assign(__assign({}, data[0].boxTwo), { assignedId: 2 }),
                    __assign(__assign({}, data[0].boxThree), { assignedId: 3 }),
                    __assign(__assign({}, data[0].boxFour), { assignedId: 4 }),
                    __assign(__assign({}, data[0].boxFive), { assignedId: 5 }),
                    __assign(__assign({}, data[0].boxSix), { assignedId: 6 }),
                    __assign(__assign({}, data[0].boxSeven), { assignedId: 7 }),
                    __assign(__assign({}, data[0].boxEight), { assignedId: 8 }),
                    __assign(__assign({}, data[0].boxNine), { assignedId: 9 }),
                ],
            },
        });
    },
});
export var createNineBoxQuery = createEndpointCall("{ninebox}/nine-box");
export var deleteNineBoxQuery = deleteEndpointCall("{ninebox}/nine-box?boxId={boxId}");
export var newVersionNineBoxQuery = putEndpointCall("{ninebox}/nine-box/{id}/new-version");
export var revertVersionNineBoxQuery = putEndpointCall("{ninebox}/nine-box/{id}/revert-version");
export var NineBoxSwitchAxisQuery = putEndpointCall("{ninebox}/nine-box/{id}/switch-axis");
export var updateNineBoxSettingsQuery = updateEndpointCall("{ninebox}/nine-box/{id}/settings");
export var updateNineBoxLeadersQuery = updateEndpointCall("{ninebox}/nine-box/{id}/box-leaders");
export var addNineBoxAuditLogQuery = updateEndpointCall("{ninebox}/nine-box/{id}/audit-log");
export var downloadNineBoxCsv = function (id, filename, filters, onSuccess) { return __awaiter(void 0, void 0, void 0, function () {
    var url, filterString, anchor, headers;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(apiBase.ninebox, "/nine-box/").concat(id, "/download");
                filterString = getLeaderScoresParamString(filters);
                if (filterString) {
                    url += "?".concat(filterString);
                }
                anchor = document.createElement("a");
                document.body.appendChild(anchor);
                return [4 /*yield*/, getHeaders()];
            case 1:
                headers = _a.sent();
                fetch(url, { headers: headers })
                    .then(function (response) {
                    return response.blob();
                })
                    .then(function (blob) {
                    var objectUrl = window.URL.createObjectURL(blob);
                    anchor.href = objectUrl;
                    anchor.download = filename;
                    anchor.click();
                    window.URL.revokeObjectURL(objectUrl);
                    onSuccess();
                });
                return [2 /*return*/];
        }
    });
}); };
export var formattedDate = function (inputDateTime) {
    var newDateFormat = new Date(inputDateTime).toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
    });
    return newDateFormat;
};
export var formattedTime = function (inputDateTime) {
    var newTimeFormat = new Date("".concat(inputDateTime, " UTC")).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    return newTimeFormat;
};
export var getBoxesWithLabels = function (data) {
    var boxes = [];
    data.boxes.forEach(function (item, index) {
        var nbox = {
            index: index,
            name: item.name,
            boxDescription: item.boxDescription,
            lastUpdatedTs: item.lastUpdatedTs,
            xlabel: data.xAxis.axisLabel,
            ylabel: data.yAxis.axisLabel,
            xDataSources: data.xAxis.dataSources,
            yDataSources: data.yAxis.dataSources,
            xplace: "",
            yplace: "",
        };
        var indexToLabel = {
            0: { xplace: data.xAxis.lowLabel, yplace: data.yAxis.lowLabel },
            1: { xplace: data.xAxis.lowLabel, yplace: data.yAxis.middleLabel },
            2: { xplace: data.xAxis.lowLabel, yplace: data.yAxis.highLabel },
            3: { xplace: data.xAxis.middleLabel, yplace: data.yAxis.lowLabel },
            4: { xplace: data.xAxis.middleLabel, yplace: data.yAxis.middleLabel },
            5: { xplace: data.xAxis.middleLabel, yplace: data.yAxis.highLabel },
            6: { xplace: data.xAxis.highLabel, yplace: data.yAxis.lowLabel },
            7: { xplace: data.xAxis.highLabel, yplace: data.yAxis.middleLabel },
            8: { xplace: data.xAxis.highLabel, yplace: data.yAxis.highLabel },
        };
        nbox.xplace = indexToLabel[index].xplace;
        nbox.yplace = indexToLabel[index].yplace;
        boxes.push(nbox);
    });
    return boxes;
};
