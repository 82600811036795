var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect } from "react";
import { Box, styled, useTheme, Typography } from "@mui/material";
import Table, { ValidSortDirections } from "../../components/Table/Table";
import FilteredOverviewPage from "../../components/FilteredOverviewPage";
import BackToButton from "../../components/BackToButton";
import { useGetPositionedSuccessionLeaderScoresQuery, useGetPositionSuccessionOverviewQuery, useGetClientDetailsQuery, prepareEightfoldDomain, } from "../../store/api/core";
import { useNavigate } from "../../hooks/navigate";
import { getRoute } from "../../routes/getAllRoutes";
import { FilterContext } from "../../providers/FilterProvider";
import { Status } from "../../common/action";
import { useSummary } from "../../hooks/assessment";
import { textLowScorerGetter, useScoreSelectorRow, } from "../../components/FilteredOverviewPage/FilteredOverviewPage";
import { SuccessionTypes, successionTooltips, } from "../../store/api/types/core";
import CardTooltip from "../../components/CardTooltip/CardTooltip";
import { HeaderFilter } from "../../components/Table/HeaderFilter";
import { PopupFilter } from "../../components/Table/PopupFilter";
import { debounce } from "lodash";
import { AvailableFilters, DefaultFilterSet, } from "../../components/Filters/Filters";
import { haveTalentReviewPages } from "../../common/featureFlags";
import { usePermissions } from "../../hooks/permissions";
import styles from "./SuccessionPage.module.scss";
var scoreInfos = [
    {
        scoreType: SuccessionTypes.AtRisk,
        title: "Positions at risk",
        tooltip: successionTooltips[SuccessionTypes.AtRisk],
    },
    {
        scoreType: SuccessionTypes.WithoutPipelines,
        title: "Positions without pipelines",
        tooltip: successionTooltips[SuccessionTypes.WithoutPipelines],
    },
    {
        scoreType: SuccessionTypes.ShortageSuccessors,
        title: "Positions with shortage of successors",
        tooltip: successionTooltips[SuccessionTypes.ShortageSuccessors],
    },
    {
        scoreType: SuccessionTypes.ShortageReadyNow,
        title: "Positions with shortage of ready now successors",
        tooltip: successionTooltips[SuccessionTypes.ShortageReadyNow],
    },
    {
        scoreType: SuccessionTypes.OverleveragedReadyNow,
        title: "Positions with over-leveraged ready now successors",
        tooltip: successionTooltips[SuccessionTypes.OverleveragedReadyNow],
    },
    {
        scoreType: SuccessionTypes.ReliantLowMatchScores,
        title: "Positions reliant on ready now successors with low match scores",
        tooltip: successionTooltips[SuccessionTypes.ReliantLowMatchScores],
    },
];
var LocalTable = function () {
    var _a;
    var _b, _c, _d, _e, _f;
    var theme = useTheme();
    var navigate = useNavigate();
    var getFilters = useContext(FilterContext).getFilters;
    var _g = React.useState(false), filterPopped = _g[0], setFilterPopped = _g[1];
    var _h = React.useState({ plan: false, pool: false }), pipelineFiltering = _h[0], setPipelineFiltering = _h[1];
    var _j = React.useState({
        y: 0,
        x: 0,
    }), position = _j[0], setPosition = _j[1];
    var scoreType = (_b = getFilters()) === null || _b === void 0 ? void 0 : _b.scoreType;
    var clientDetails = useGetClientDetailsQuery({});
    var eightfoldDomain = prepareEightfoldDomain((_d = (_c = clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.domain);
    var handleEightfoldUserNavigation = function (id, navigate) {
        if (id) {
            var url = "".concat(eightfoldDomain, "/careerhub/profile/").concat(id);
            window.location.href = url;
        }
        else {
            navigate(getRoute("UserNotFound"));
        }
    };
    var handleEightfoldPipelineNavigation = function (id, navigate) {
        if (id) {
            var url = "".concat(eightfoldDomain, "/careerhub/team-planning/succession/manage/").concat(id);
            window.location.href = url;
        }
        else {
            navigate(getRoute("PipelineNotFound"));
        }
    };
    var leaderScores = useGetPositionedSuccessionLeaderScoresQuery({
        filters: getFilters(),
    });
    useEffect(function () {
        leaderScores.refetch();
    }, [getFilters]);
    var DualSpan = function (_a) {
        var when = _a.when;
        return (_jsxs(Box, { children: [_jsxs(Box, __assign({ sx: {
                        position: "absolute",
                        bottom: "55px",
                        whiteSpace: "nowrap",
                    } }, { children: ["Ready ", when] })), _jsx(Box, { children: "Count" })] }));
    };
    var LinedSpan = function (_a) {
        var values = _a.values;
        return (_jsx(Box, { children: values.map(function (text, index) { return (_jsx(Box, { children: text }, index)); }) }));
    };
    var PipelineTypeHeader = function () {
        var HeaderText = styled(Box)({
            display: "inline-block",
            paddingRight: "5px",
            width: "100px",
        });
        var StrongText = styled(Box)({
            display: "inline-block",
            fontFamily: theme.typography.fontFamily + " Heavy",
        });
        var Description = styled(Typography)({
            fontFamily: theme.typography.fontFamily,
            textAlign: "left",
            fontSize: "14px",
        });
        var handleElement = debounce(function () {
            var element = document.getElementById("header-filter");
            var rectangle = (element === null || element === void 0 ? void 0 : element.getBoundingClientRect()) || { y: 0, x: 0 };
            var x = rectangle.x, y = rectangle.y;
            if (x == 0 || y == 0) {
                return handleElement();
            }
            var xx = x - 250;
            var yy = y + 40 + window.scrollY;
            if (xx != position.x || yy != position.y) {
                setPosition({ x: xx, y: yy });
            }
        }, 500);
        useEffect(function () {
            handleElement();
        }, []);
        window.addEventListener("resize", handleElement);
        return (_jsxs(Box, __assign({ sx: {
                display: "flex",
            } }, { children: [_jsx(HeaderText, { children: "Pipeline Type" }), _jsx(Box, __assign({ sx: {
                        position: "relative",
                        top: "4px",
                        right: "5px",
                    } }, { children: _jsx(CardTooltip, { tooltip: _jsxs("div", __assign({ style: { minWidth: "120px" } }, { children: [_jsxs(Description, __assign({ sx: { paddingBottom: "8px" } }, { children: ["A succession ", _jsx(StrongText, { children: "position plan" }), " is a pipeline for a single, specific position within the organization."] })), _jsxs(Description, { children: ["A succession ", _jsx(StrongText, { children: "role plan" }), " is a pipeline for a role that could have multiple positions."] })] })) }) })), _jsx(Box, __assign({ id: "header-filter", sx: {
                        position: "relative",
                        top: "2px",
                        width: "30px",
                    } }, { children: _jsx(HeaderFilter, { onChange: function (state) {
                            handleElement();
                            setTimeout(function () {
                                setFilterPopped(state);
                            }, 200);
                        }, filterPopped: filterPopped }) }))] })));
    };
    var WidthedHeader = styled(Box)({ width: "90px" });
    var headers = [
        "Position | Incumbent",
        "Role",
        _jsx(PipelineTypeHeader, {}),
        _jsx(WidthedHeader, { children: "Successors" }),
        _jsx(DualSpan, { when: "Now" }),
        _jsx(LinedSpan, { values: ["Match", "Score"] }),
        _jsx(DualSpan, { when: "Soon" }),
        _jsx(LinedSpan, { values: ["Match", "Score"] }),
        _jsx(DualSpan, { when: "Later" }),
        _jsx(LinedSpan, { values: ["Match", "Score"] }),
        _jsx(LinedSpan, { values: ["Ready now", "successors on", "3+ pipelines"] }),
    ];
    var tableData = (((_f = (_e = leaderScores === null || leaderScores === void 0 ? void 0 : leaderScores.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.leaders) || [])
        .filter(function (record) { var _a; return scoreType == null || ((_a = record === null || record === void 0 ? void 0 : record[scoreType]) === null || _a === void 0 ? void 0 : _a.state) == Status.Act; })
        .map(function (_a) {
        var _b, _c, _d, _e;
        var first_name = _a.first_name, last_name = _a.last_name, position_name = _a.position_name, role_name = _a.role_name, ef_id = _a.ef_id, position_plan = _a.position_plan, role_plan = _a.role_plan;
        var getCountMatchSet = function (ready) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            var positionPlanCount = (_b = (_a = position_plan === null || position_plan === void 0 ? void 0 : position_plan["ready_".concat(ready, "_successors")]) === null || _a === void 0 ? void 0 : _a.count) === null || _b === void 0 ? void 0 : _b.value;
            var rolePlanCount = (_d = (_c = role_plan === null || role_plan === void 0 ? void 0 : role_plan["ready_".concat(ready, "_successors")]) === null || _c === void 0 ? void 0 : _c.count) === null || _d === void 0 ? void 0 : _d.value;
            var positionPlanMatchScore = (_f = (_e = position_plan === null || position_plan === void 0 ? void 0 : position_plan["ready_".concat(ready, "_successors")]) === null || _e === void 0 ? void 0 : _e.match_score) === null || _f === void 0 ? void 0 : _f.value;
            var rolePlanMatchScore = (_h = (_g = role_plan === null || role_plan === void 0 ? void 0 : role_plan["ready_".concat(ready, "_successors")]) === null || _g === void 0 ? void 0 : _g.match_score) === null || _h === void 0 ? void 0 : _h.value;
            return [
                {
                    sort: isNaN(positionPlanCount)
                        ? rolePlanCount
                        : positionPlanCount,
                    data: {
                        plan: (_j = position_plan === null || position_plan === void 0 ? void 0 : position_plan["ready_".concat(ready, "_successors")]) === null || _j === void 0 ? void 0 : _j.count,
                        pool: (_k = role_plan === null || role_plan === void 0 ? void 0 : role_plan["ready_".concat(ready, "_successors")]) === null || _k === void 0 ? void 0 : _k.count,
                    },
                },
                {
                    sort: isNaN(positionPlanMatchScore)
                        ? rolePlanMatchScore
                        : positionPlanMatchScore,
                    data: {
                        plan: (_l = position_plan === null || position_plan === void 0 ? void 0 : position_plan["ready_".concat(ready, "_successors")]) === null || _l === void 0 ? void 0 : _l.match_score,
                        pool: (_m = role_plan === null || role_plan === void 0 ? void 0 : role_plan["ready_".concat(ready, "_successors")]) === null || _m === void 0 ? void 0 : _m.match_score,
                    },
                },
            ];
        };
        var successorsRolePlanCount = (_b = role_plan === null || role_plan === void 0 ? void 0 : role_plan.successors) === null || _b === void 0 ? void 0 : _b.value;
        var successorsPositionPlanCount = (_c = position_plan === null || position_plan === void 0 ? void 0 : position_plan.successors) === null || _c === void 0 ? void 0 : _c.value;
        var multiplePipelinesRolePlanCount = (_d = role_plan === null || role_plan === void 0 ? void 0 : role_plan.multiple_pipelines) === null || _d === void 0 ? void 0 : _d.value;
        var multiplePipelinesPositionPlanCount = (_e = position_plan === null || position_plan === void 0 ? void 0 : position_plan.multiple_pipelines) === null || _e === void 0 ? void 0 : _e.value;
        return __spreadArray(__spreadArray(__spreadArray(__spreadArray([
            {
                sort: "".concat(position_name),
                data: {
                    first_name: first_name,
                    last_name: last_name,
                    position_name: position_name,
                    ef_id: ef_id,
                },
            },
            {
                sort: role_name,
                data: role_name,
            },
            {
                sort: 1,
                data: {
                    plan: Object.keys(position_plan || {}).length > 0,
                    pool: role_plan === null || role_plan === void 0 ? void 0 : role_plan.incumbents,
                    efPlanId: position_plan === null || position_plan === void 0 ? void 0 : position_plan.ef_plan_id,
                    efPoolId: role_plan === null || role_plan === void 0 ? void 0 : role_plan.ef_plan_id,
                },
            },
            {
                sort: isNaN(successorsPositionPlanCount)
                    ? successorsRolePlanCount
                    : successorsPositionPlanCount,
                data: {
                    plan: position_plan === null || position_plan === void 0 ? void 0 : position_plan.successors,
                    pool: role_plan === null || role_plan === void 0 ? void 0 : role_plan.successors,
                },
            }
        ], getCountMatchSet("now"), true), getCountMatchSet("soon"), true), getCountMatchSet("later"), true), [
            {
                sort: isNaN(multiplePipelinesPositionPlanCount)
                    ? multiplePipelinesRolePlanCount
                    : multiplePipelinesPositionPlanCount,
                data: {
                    plan: position_plan === null || position_plan === void 0 ? void 0 : position_plan.multiple_pipelines,
                    pool: role_plan === null || role_plan === void 0 ? void 0 : role_plan.multiple_pipelines,
                },
            },
        ], false);
    })
        .filter(function (row) {
        return (!pipelineFiltering.pool && !pipelineFiltering.plan) ||
            (!!row[2].data.pool && pipelineFiltering.pool) ||
            (!!row[2].data.plan && pipelineFiltering.plan);
    });
    var NameFormatter = function (_a) {
        var data = _a.data;
        return (_jsxs(Box, __assign({ onClick: function () { return handleEightfoldUserNavigation(data.ef_id, navigate); }, sx: {
                cursor: "pointer",
            } }, { children: [_jsx("div", __assign({ className: styles.leaderRole }, { children: data.position_name })), _jsxs("div", __assign({ className: styles.leaderName }, { children: [data.first_name, " ", data.last_name] }))] })));
    };
    var PlanPoolSetContainer = styled(Box)({
        display: "flex",
        flexDirection: "column",
        borderRadius: "4px",
        gap: "8px",
    });
    var PipelineType = function (_a) {
        var _b = _a.data, efPlanId = _b.efPlanId, efPoolId = _b.efPoolId, pool = _b.pool, plan = _b.plan;
        var GreyBubble = styled(Box)({
            display: "flex",
            padding: "2px 4px",
            background: theme.palette.grey.A200,
            borderRadius: "4px",
            gap: "12px",
            width: "fit-content",
            cursor: "pointer",
        });
        if (!(pool || plan)) {
            return "-";
        }
        return (_jsxs(PlanPoolSetContainer, { children: [plan && (_jsx(GreyBubble, __assign({ onClick: function () {
                        return handleEightfoldPipelineNavigation(efPlanId, navigate);
                    } }, { children: _jsx(Box, { children: "Position Plan" }) }))), pool && (_jsxs(GreyBubble, __assign({ onClick: function () {
                        return handleEightfoldPipelineNavigation(efPoolId, navigate);
                    } }, { children: [_jsx(Box, __assign({ sx: {} }, { children: "Role Plan" })), _jsx(Box, __assign({ sx: {
                                background: theme.palette.background.default,
                                padding: "0 3px",
                                radius: "4px",
                                color: "#343C4C",
                                fontFamily: theme.typography.fontFamily + " Heavy",
                            } }, { children: pool }))] })))] }));
    };
    var ColoredBubble = function (_a) {
        var valueFormatter = _a.valueFormatter, data = _a.data, isColored = _a.isColored;
        return data.value === null ? ("-") : (_jsx(Box, __assign({ sx: __assign(__assign({}, (isColored
                ? {
                    textAlign: "center",
                    background: theme.palette[data.state == Status.Act
                        ? "error"
                        : data.state == Status.Watch
                            ? "warning"
                            : "success"].main,
                    border: "1px solid ".concat(theme.palette.grey.A400),
                }
                : {})), { width: "60px", height: "24px", borderRadius: "10px" }) }, { children: _jsx(Box, __assign({ sx: {
                    position: "relative",
                    top: "3px",
                } }, { children: valueFormatter(data.value) })) })));
    };
    var echoFormatter = function (value) { return value; };
    var matchFormatter = function (value) {
        return value === null ? "-" : isNaN(value) ? "" : value.toFixed(2);
    };
    var ColoredBubbleSet = function (valueFormatter, isColored) {
        return function (_a) {
            var _b = _a.data, plan = _b.plan, pool = _b.pool;
            return !((plan === null || plan === void 0 ? void 0 : plan.state) || (pool === null || pool === void 0 ? void 0 : pool.state)) ? ("-") : (_jsxs(PlanPoolSetContainer, __assign({ sx: {
                    gap: "15px",
                } }, { children: [plan && (_jsx(ColoredBubble, { valueFormatter: valueFormatter, data: plan, isColored: isColored })), pool && (_jsx(ColoredBubble, { valueFormatter: valueFormatter, data: pool, isColored: isColored }))] })));
        };
    };
    var TextFormatter = function (_a) {
        var data = _a.data;
        return _jsx(Box, { children: data });
    };
    var formatters = [
        NameFormatter,
        TextFormatter,
        PipelineType,
        ColoredBubbleSet(echoFormatter, true),
        ColoredBubbleSet(echoFormatter, true),
        ColoredBubbleSet(matchFormatter, true),
        ColoredBubbleSet(echoFormatter, false),
        ColoredBubbleSet(matchFormatter, false),
        ColoredBubbleSet(echoFormatter, false),
        ColoredBubbleSet(matchFormatter, false),
        ColoredBubbleSet(echoFormatter, true),
    ];
    var sortColumn = (_a = {},
        _a[SuccessionTypes.AtRisk] = 0,
        _a[SuccessionTypes.WithoutPipelines] = 0,
        _a[SuccessionTypes.ShortageSuccessors] = 3,
        _a[SuccessionTypes.ShortageReadyNow] = 4,
        _a[SuccessionTypes.OverleveragedReadyNow] = 10,
        _a[SuccessionTypes.ReliantLowMatchScores] = 5,
        _a)[scoreType];
    return (_jsxs(_Fragment, { children: [_jsx(Table, { showingInfo: function (_a) {
                    var total = _a.total;
                    return "Showing ".concat(total, " Position").concat(total != 1 ? "s" : "");
                }, data: tableData, headers: headers, formatters: formatters, rowSx: { ":hover": { background: "#EBFDFF" } }, title: "", paginationThreshold: 10, unsortableColumns: [2, 11], highlightColumnIndex: sortColumn || 0, defaultSortColumnIndex: sortColumn || 0, defaultSortDirection: ValidSortDirections.ASC }), filterPopped && (_jsx(PopupFilter, { sx: {
                    top: "".concat(position.y, "px"),
                    left: "".concat(position.x, "px"),
                }, doFiltering: function (value) {
                    setPipelineFiltering(value);
                    setFilterPopped(false);
                }, startValues: pipelineFiltering }))] }));
};
var BottomRowPart = function (_a) {
    var info = _a.info;
    var bottomRow = useScoreSelectorRow({
        textGetter: textLowScorerGetter,
        initialScoreType: SuccessionTypes.AtRisk,
        scoreInfos: [info],
        useScores: useGetPositionedSuccessionLeaderScoresQuery,
    });
    return bottomRow;
};
var SuccessionPage = function () {
    var title = "Intelligence - Succession";
    var nineBox = usePermissions().nineBox;
    var availableFilters = new Set(__spreadArray([], Array.from(DefaultFilterSet), true));
    if (haveTalentReviewPages() && nineBox) {
        availableFilters.add(AvailableFilters.NineBox);
    }
    var bottomRow = scoreInfos.map(function (info) { return _jsx(BottomRowPart, { info: info }); });
    var useSummaryInformation = useSummary({
        useGetOverviewQuery: useGetPositionSuccessionOverviewQuery,
        useGetScoresQuery: useGetPositionedSuccessionLeaderScoresQuery,
    });
    return (_jsx(FilteredOverviewPage, __assign({ availableFilters: availableFilters, title: title, useSummaryInformation: useSummaryInformation, scoreInfos: scoreInfos, backToButton: _jsx(BackToButton, { title: "Succession", url: getRoute("DashboardSuccession") }), secondaryHeadingTitle: "Positions", bottomRow: bottomRow }, { children: _jsx(LocalTable, {}) })));
};
export default SuccessionPage;
