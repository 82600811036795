var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext } from "react";
import SecondaryMainHeading from "../SecondMainHeading/SecondMainHeading";
import CardBox from "../CardBox/CardBox";
import { Box } from "@mui/material";
import { useFilterComponents } from "../Filters/Filters";
import { useScoreCardComponents } from "./Scores";
import { percentageOfScoreFormatterBase } from "../LevelFunctionMatrix/Variants";
import { ControlledOverviewTabs, } from "../OveriewTabs/OverviewTabs";
import { FilterContext } from "../../providers/FilterProvider";
export var textLowScorerGetter = function (count, score, totalCount) {
    return percentageOfScoreFormatterBase({ value: score, count: count }, totalCount);
};
export function useScoreSelectorRow(_a) {
    var _b;
    var textGetter = _a.textGetter, useScores = _a.useScores, initialScoreType = _a.initialScoreType, scoreInfos = _a.scoreInfos;
    var _c = useContext(FilterContext), updateFilters = _c.updateFilters, getFilters = _c.getFilters;
    React.useEffect(function () {
        if (getFilters().scoreType === undefined) {
            updateFilters({ scoreType: initialScoreType });
        }
    }, [(_b = getFilters()) === null || _b === void 0 ? void 0 : _b.scoreType]);
    var components = useScoreCardComponents({
        textGetter: textGetter,
        scoreInfos: scoreInfos,
        useScores: useScores,
    });
    return components;
}
var LocalCardBox = function (_a) {
    var bottomRow = _a.bottomRow, availableFilters = _a.availableFilters, useSummaryInformation = _a.useSummaryInformation, filterClearer = _a.filterClearer;
    var topRow = useFilterComponents({
        useSummary: useSummaryInformation,
        availableFilters: availableFilters,
        clearFilters: filterClearer,
    });
    return _jsx(CardBox, { topRow: topRow, bottomRow: bottomRow });
};
function FilteredOverviewBase(_a) {
    var children = _a.children, title = _a.title, backToButton = _a.backToButton, secondaryHeadingTitle = _a.secondaryHeadingTitle;
    React.useEffect(function () {
        document.title = title;
    }, []);
    return (_jsxs(Box, __assign({ "data-testid": "FilteredOverviewPage" }, { children: [_jsx(SecondaryMainHeading, { backToButton: backToButton, "data-testid": "SecondaryHeading", secondaryHeadingTitle: secondaryHeadingTitle }), children] })));
}
export function TabbedFilteredOverviewPage(_a) {
    var useSummaryInformation = _a.useSummaryInformation, tabs = _a.tabs, title = _a.title, backToButton = _a.backToButton, secondaryHeadingTitle = _a.secondaryHeadingTitle, bottomRow = _a.bottomRow, availableFilters = _a.availableFilters, filterClearer = _a.filterClearer;
    return (_jsx(FilteredOverviewBase, __assign({ title: title, backToButton: backToButton, secondaryHeadingTitle: secondaryHeadingTitle }, { children: _jsx(ControlledOverviewTabs, { tabs: tabs.map(function (tab) { return (__assign(__assign({}, tab), { content: (_jsxs(_Fragment, { children: [_jsx(LocalCardBox, { useSummaryInformation: useSummaryInformation, bottomRow: bottomRow, availableFilters: availableFilters, filterClearer: filterClearer }), _jsx(Box, __assign({ sx: {
                                padding: "0 32px",
                            } }, { children: _jsx(_Fragment, { children: tab.content }) }))] })) })); }) }) })));
}
export function FilteredOverviewPage(_a) {
    var useSummaryInformation = _a.useSummaryInformation, children = _a.children, title = _a.title, backToButton = _a.backToButton, secondaryHeadingTitle = _a.secondaryHeadingTitle, bottomRow = _a.bottomRow, availableFilters = _a.availableFilters, filterClearer = _a.filterClearer;
    return (_jsxs(FilteredOverviewBase, __assign({ title: title, backToButton: backToButton, secondaryHeadingTitle: secondaryHeadingTitle }, { children: [_jsx(LocalCardBox, { useSummaryInformation: useSummaryInformation, bottomRow: bottomRow, availableFilters: availableFilters, filterClearer: filterClearer }), _jsx(Box, __assign({ sx: {
                    padding: "0 32px",
                } }, { children: _jsx(_Fragment, { children: children }) }))] })));
}
export default FilteredOverviewPage;
