var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { DiversityTable, } from "../../components/DiversityTable/DiversityTable";
import { useGetDiversityLeaders, useGetLeadershipOverviewQuery, } from "../../store/api/core";
import FilteredOverviewPage from "../../components/FilteredOverviewPage/FilteredOverviewPage";
import BackToButton from "../../components/BackToButton/BackToButton";
import { useSummary } from "../../hooks/assessment";
import { AvailableFilters, DefaultFilterSet, } from "../../components/Filters/Filters";
import { getRoute } from "../../routes/getAllRoutes";
import { haveTalentReviewPages } from "../../common/featureFlags";
import { usePermissions } from "../../hooks/permissions";
var isEthnicDiverse = function (_a) {
    var is_ethnically_diverse = _a.is_ethnically_diverse;
    return !!is_ethnically_diverse;
};
var isNotEthnicDiverse = function (_a) {
    var is_ethnically_diverse = _a.is_ethnically_diverse;
    return !is_ethnically_diverse;
};
var isEthnicUndeclared = function (_a) {
    var ethnicity = _a.ethnicity;
    return !ethnicity;
};
var isNotEthnicUndeclared = function (_a) {
    var ethnicity = _a.ethnicity;
    return !!ethnicity;
};
var isGenderDiverse = function (_a) {
    var is_gender_diverse = _a.is_gender_diverse;
    return !!is_gender_diverse;
};
var isNotGenderDiverse = function (_a) {
    var is_gender_diverse = _a.is_gender_diverse;
    return !is_gender_diverse;
};
var isGenderUndeclared = function (_a) {
    var gender = _a.gender;
    return !gender;
};
var isNotGenderUndeclared = function (_a) {
    var gender = _a.gender;
    return !!gender;
};
var isOnSuccessionPipeline = function (_a) {
    var succession_plans_count = _a.succession_plans_count;
    return succession_plans_count > 0;
};
var isNotOnSuccessionPipeline = function (_a) {
    var succession_plans_count = _a.succession_plans_count;
    return succession_plans_count == 0;
};
export function DiversitySecondaryPage(_a) {
    var filters = _a.filters, title = _a.title;
    var useSummaryInformation = useSummary({
        useGetScoresQuery: useGetDiversityLeaders,
        useGetOverviewQuery: useGetLeadershipOverviewQuery,
    });
    var nineBox = usePermissions().nineBox;
    var availableFilters = new Set(__spreadArray(__spreadArray([], Array.from(DefaultFilterSet), true), [
        AvailableFilters.Gender,
        AvailableFilters.Ethnicity,
    ], false));
    if (haveTalentReviewPages() && nineBox) {
        availableFilters.add(AvailableFilters.NineBox);
    }
    return (_jsx(FilteredOverviewPage, __assign({ availableFilters: availableFilters, useSummaryInformation: useSummaryInformation, title: title, secondaryHeadingTitle: title, backToButton: _jsx(BackToButton, { title: "Diversity", url: getRoute("DashboardDiversity") }) }, { children: _jsx(DiversityTable, { filters: filters }) })));
}
export var GenderDiverse = function () { return (_jsx(DiversitySecondaryPage, { filters: [isGenderDiverse], title: "Gender-diverse leaders" })); };
export var NonGenderDiverse = function () { return (_jsx(DiversitySecondaryPage, { filters: [isNotGenderDiverse, isNotGenderUndeclared], title: "Non-gender diverse leaders" })); };
export var GenderUndeclared = function () { return (_jsx(DiversitySecondaryPage, { filters: [isGenderUndeclared], title: "Leaders with undeclared gender" })); };
export var EthnicallyDiverse = function () { return (_jsx(DiversitySecondaryPage, { filters: [isEthnicDiverse], title: "Ethnically diverse leaders" })); };
export var NonEthnicallyDiverse = function () { return (_jsx(DiversitySecondaryPage, { filters: [isNotEthnicDiverse, isNotEthnicUndeclared], title: "Non-ethnically diverse leaders" })); };
export var EthnicallyUndeclared = function () { return (_jsx(DiversitySecondaryPage, { filters: [isEthnicUndeclared], title: "Leaders with undeclared ethnicity" })); };
export var OnPipeline = function () { return (_jsx(DiversitySecondaryPage, { filters: [isOnSuccessionPipeline], title: "Leaders on pipelines" })); };
export var NotOnPipeline = function () { return (_jsx(DiversitySecondaryPage, { filters: [isNotOnSuccessionPipeline], title: "Leaders not on pipelines" })); };
export var GenderDiverseOnPipeline = function () { return (_jsx(DiversitySecondaryPage, { filters: [isOnSuccessionPipeline, isGenderDiverse], title: "Gender-diverse leaders on pipelines" })); };
export var GenderDiverseNotOnPipeline = function () { return (_jsx(DiversitySecondaryPage, { filters: [isNotOnSuccessionPipeline, isGenderDiverse], title: "Gender-diverse leaders not on pipelines" })); };
export var EthnicallyDiverseOnPipeline = function () { return (_jsx(DiversitySecondaryPage, { filters: [isOnSuccessionPipeline, isEthnicDiverse], title: "Ethnically-diverse leaders on pipelines" })); };
export var EthnicallyDiverseNotOnPipeline = function () { return (_jsx(DiversitySecondaryPage, { filters: [isNotOnSuccessionPipeline, isEthnicDiverse], title: "Ethnically-diverse leaders not on pipelines" })); };
export var GenderEthnicallyDiverseOnPipeline = function () { return (_jsx(DiversitySecondaryPage, { filters: [isOnSuccessionPipeline, isEthnicDiverse, isGenderDiverse], title: "Gender & ethnically diverse leaders on pipelines" })); };
export var GenderEthnicallyDiverseNotOnPipeline = function () { return (_jsx(DiversitySecondaryPage, { filters: [isNotOnSuccessionPipeline, isEthnicDiverse, isGenderDiverse], title: "Gender & ethnically diverse leaders not on pipelines" })); };
