var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useState, useEffect } from "react";
import { Box, styled, useTheme, List, ListItem } from "@mui/material";
import { Button, ButtonIconAlign, ButtonShape, ButtonSize, ButtonVariant, Dropdown as OctupleDropdown, IconName, } from "@eightfold.ai/octuple";
import CheckBox from "../CheckBox/CheckBox";
var debounce = function (func, delay) {
    var timeoutId;
    return function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        clearTimeout(timeoutId);
        timeoutId = setTimeout(function () {
            func.apply(_this, args);
        }, delay);
    };
};
var debouncedOnChangeSelect = debounce(function (selected, onChange) {
    onChange(selected);
}, 1000);
var Dropdown = function (_a) {
    var options = _a.options, initial = _a.initial, label = _a.label, inactiveLabel = _a.inactiveLabel, multiple = _a.multiple, onChangeMultiple = _a.onChangeMultiple, renderCounter = _a.renderCounter, buttonOverrideProps = _a.buttonOverrideProps, dropdownOverrideProps = _a.dropdownOverrideProps, iconOverrideProps = _a.iconOverrideProps;
    var theme = useTheme();
    var mounted = React.useRef(false);
    function determineInitialState(initial) {
        if (typeof initial === "number") {
            return [initial];
        }
        else if (Array.isArray(initial)) {
            return initial;
        }
        else {
            return [];
        }
    }
    var initialState = determineInitialState(initial);
    var _b = useState(initialState), selected = _b[0], setSelected = _b[1];
    var handleChangeSelect = function () {
        if (!mounted.current) {
            mounted.current = true;
            // Only run onChange after the component has been mounted
            return;
        }
        if (multiple) {
            if (onChangeMultiple) {
                debouncedOnChangeSelect(selected, onChangeMultiple);
            }
        }
        else {
            var selectedOption = options === null || options === void 0 ? void 0 : options[selected[0]];
            if (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.onChange) {
                selectedOption.onChange();
            }
        }
    };
    useEffect(handleChangeSelect, [selected]);
    useEffect(function () {
        if (JSON.stringify(initialState) != JSON.stringify(selected)) {
            setSelected(initialState);
        }
    }, [initialState]);
    var handleClick = function (clickIndex) {
        if (multiple) {
            var filtered = selected.filter(function (value) { return value != clickIndex; });
            if (filtered.length != selected.length) {
                filtered.sort();
                setSelected(filtered);
                return filtered;
            }
            var current = __spreadArray(__spreadArray([], selected, true), [clickIndex], false);
            current.sort();
            setSelected(current);
        }
        else {
            setSelected([clickIndex]);
        }
    };
    var buttonProps = {
        variant: ButtonVariant.Neutral,
        counter: "",
    };
    if (selected.length) {
        buttonProps.variant = ButtonVariant.Primary;
    }
    if (selected.length === 1 && (options === null || options === void 0 ? void 0 : options[selected[0]])) {
        // Don't make the button primary if the selected option is inactive
        if ((options === null || options === void 0 ? void 0 : options[selected[0]].label) === inactiveLabel) {
            buttonProps.variant = ButtonVariant.Neutral;
        }
    }
    var defaultRenderCounter = function (options, selectedValues) {
        var counter = "";
        if (selectedValues.length) {
            counter = selected.length.toString();
        }
        if (selectedValues.length === 1 && (options === null || options === void 0 ? void 0 : options[selectedValues[0]])) {
            counter = "".concat(options === null || options === void 0 ? void 0 : options[selectedValues[0]].label);
        }
        if (selectedValues.length === 0 && multiple) {
            counter = inactiveLabel || "";
        }
        return counter;
    };
    buttonProps.counter = renderCounter
        ? renderCounter(options || [], selected || [])
        : defaultRenderCounter(options || [], selected || []);
    var DropdownItem = function (_a) {
        var label = _a.label, optionIndex = _a.optionIndex;
        var DropdownItemContainer = styled(ListItem)({
            display: "flex",
            alignItems: "center",
            padding: "6px 12px",
            cursor: "pointer",
        });
        var hoverStyles = {};
        if (!multiple) {
            hoverStyles = {
                "&:hover": {
                    backgroundColor: "#D5D9DE",
                    borderRadius: "10px",
                },
            };
        }
        return (_jsx(DropdownItemContainer, __assign({ sx: hoverStyles, "data-test-id": "dropdown-item-".concat(optionIndex), onClick: function () {
                handleClick(optionIndex);
            } }, { children: _jsx(CheckBox, { value: "".concat(optionIndex), label: label, checked: selected.includes(optionIndex) }) })));
    };
    var dropdownOptions = function () {
        var listStyles = {};
        if (!multiple) {
            listStyles = {
                "&.dropdown-list [class^='checkmark']": {
                    display: "none",
                },
                "&.dropdown-list [class^='selector-label']": {
                    marginLeft: "0",
                },
            };
        }
        return (_jsx(List, __assign({ className: "dropdown-list", sx: listStyles }, { children: options === null || options === void 0 ? void 0 : options.map(function (option, optionIndex) { return (_jsx(DropdownItem, { label: option.label, optionIndex: optionIndex }, "item-".concat(optionIndex))); }) })));
    };
    var extraBadgeStyles = {};
    if (!label) {
        // Fix a bug where the counter text is flipped when no label is provided
        extraBadgeStyles = {
            ".dropdown [class^='badge'], .dropdown [class*=' badge']": {
                transform: "scaleX(-1)",
            },
        };
    }
    return (_jsx(Box, __assign({ sx: __assign({ "--button-font-family": "".concat(theme.typography.button.fontFamily), "--badge-font-family": "".concat(theme.typography.button.fontFamily), ".dropdown-button": {
                padding: "0 12px",
            }, ".dropdown-button [class^='button-text']": {
                fontWeight: "initial",
            }, ".dropdown [class^='counter'], .dropdown [class*=' counter']": {
                fontSize: "16px",
                fontWeight: "initial",
                height: "24px",
                lineHeight: "24px",
                padding: "0 6px",
                paddingTop: "1px",
                backgroundColor: "white",
                borderRadius: "4px",
            } }, extraBadgeStyles) }, { children: _jsx(OctupleDropdown, __assign({ initialFocus: true, classNames: "dropdown", overlay: dropdownOptions(), placement: "bottom-start", dropdownStyle: {
                "--font-stack-full": "".concat(theme.typography.button.fontFamily),
            }, positionStrategy: "absolute", trigger: "click", closeOnDropdownClick: multiple ? false : true }, (dropdownOverrideProps || {}), { children: _jsx(Button, __assign({ ariaLabel: "Dropdown Button", classNames: "dropdown-button", shape: ButtonShape.Pill, size: ButtonSize.Medium, text: "".concat(label || ""), alignIcon: ButtonIconAlign.Right, iconProps: __assign({ ariaHidden: true, "data-test-id": "dropdown-".concat(label, "-icon"), path: IconName.mdiChevronDown, role: "presentation", rotate: 0, spin: false, vertical: false }, (iconOverrideProps || {})) }, buttonProps, (buttonOverrideProps || {}))) })) })));
};
export default Dropdown;
